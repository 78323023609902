import React from "react";
import './Select.css';

class Select extends React.Component{
    constructor(props){
        super(props);
    }
    render(){
        return (
            <div className="select">
                <select name={this.props.name} {...this.props} >
                    {this.props?.items?.map(e =>  <option value={e.value??e.name} >{e.name}</option> )??null}
                </select>
            </div>
        );
    }
}

export default Select;