import Dexie from 'dexie';

window.authtoken = false; 

window.indexDbsupported = false;

document.addEventListener("DOMContentLoaded",function(e){
    (async()=>{
        if(retrieveSessionToken()){
            console.log('Session Token Found');
            window.authtoken = await retrieveSessionToken();
        }
    })()
});




async function retrieveSessionToken(){
    if(window.indexDbsupported){
        var dsb = new Dexie("SessionsDB");
        dsb.version(1).stores({
            authtoken: '++id,status,token'
        });
        const data = await dsb.authtoken.toCollection().last();
        return await data.token ?? false;
    }else{
        return localStorage.getItem('session') == null ? false : localStorage.getItem('session') ;
    }
}

async function  writeSessionToken(token){
    if(window.indexDbsupported){
        var dsb = new Dexie("SessionsDB");
        dsb.version(1).stores({
            authtoken: '++id,status,token'
        });
        await dsb.authtoken.add({status:'active',token:token});
    }
    else{
        localStorage.setItem('session',token);
    }
}
function deleteSessionToken(){
    if(window.indexDbsupported){
        var dsb = new Dexie("SessionsDB");
        dsb.version(1).stores({
            authtoken: '++id,status,token'
        });
        dsb.authtoken.toCollection().delete();
    }else{
        localStorage.removeItem('session');
    }
}

function errorListener(errors){
    console?.log(errors)
    if(['auth.required_session','auth.invalid_session','auth.device_mismatch'].includes(errors[0].code)){
        console.log("AUTH_REQUIRED Handler");
        window.authtoken = false;
        deleteSessionToken();
    }
}
let endpoint = 'https://api.republica.vision';
window.authtoken = retrieveSessionToken()


export let API = {
    auth:async (obj)=>fetch(endpoint+'/auth',{
                method:'POST',
                headers:{"Content-Type":'application/json'},
                mode:'cors',
                body:JSON.stringify(obj)
            })
                .then(async(resp)=>{
                    if(resp.ok) return resp.json()
                    else throw (await resp.json())
                })
                .then((data)=>{
                    console.log('fezgr')
                    window.authtoken = data.token;
                    return new Promise(async (resolve,reject)=>{
                        await writeSessionToken(data.token);
                        resolve(data);
                    })
                }),
    deauth:async ()=>fetch(endpoint+'/deauth',{
                method:'GET',
                headers:{
                    "Content-Type":'application/json',
                    "Authorization" : window.authtoken 
                },
                mode:'cors'
            })
                .then(async(resp)=>{
                    if(resp.ok) return resp.json()
                    else throw (await resp.json())
                })
                .catch((err)=>{
                    throw ([{code:"local.network",path:["*"]}]); // eslint-disable-line
                })
                .then(async (data)=>{
                    window.authtoken = false;
                    deleteSessionToken();
                    return data;
                }),
        
    sessions:{
        list:()=>fetch(endpoint+'/sessions',{
                method:'GET',
                headers:{
                    "Content-Type":'application/json',
                    "Authorization" : window.authtoken 
                },
                mode:'cors'
            })
                .then(async(resp)=>{
                    if(resp.ok) return resp.json()
                    else throw (await resp.json())
                })
                .catch((err)=>{
                    throw ([{code:"local.network",path:["*"]}]); // eslint-disable-line
                }),
        delete:(id)=>fetch(endpoint+'/sessions/'+id,{
                method:'DELETE',
                headers:{
                    "Content-Type":'application/json',
                    "Authorization" : window.authtoken 
                },
                mode:'cors'
            })
                .then(async(resp)=>{
                    if(resp.ok) return resp.json()
                    else throw (await resp.json())
                })
                .catch((err)=>{
                    throw ([{code:"local.network",path:["*"]}]); // eslint-disable-line
                })
    },

    access:{
        list:()=>fetch(endpoint+'/access',{
                method:'GET',
                headers:{
                    "Content-Type":'application/json',
                    "Authorization" : window.authtoken 
                },
                mode:'cors'
            })
                .then(async(resp)=>{
                    if(resp.ok) return resp.json()

                    let a = (await resp.json())
                    errorListener(a)
                    throw a
                })
                .catch((err)=>{
                    throw ([{code:"local.network",path:["*"]}]); // eslint-disable-line
                }),
        set:(id)=>fetch(endpoint+'/access/'+id+'/set',{
                method:'GET',
                headers:{
                    "Content-Type":'application/json',
                    "Authorization" : window.authtoken 
                },
                mode:'cors'
            })
                .then(async(resp)=>{
                    if(resp.ok) return resp.json()
                    else throw (await resp.json())
                })
                .catch((err)=>{
                    throw ([{code:"local.network",path:["*"]}]); // eslint-disable-line
                }),
    },

    settings:{
        password:{
            update:(obj)=>fetch(endpoint+'/settings/password/update',{
                    method:'POST',
                    headers:{
                        "Content-Type":'application/json',
                        "Authorization" : window.authtoken 
                    },
                    mode:'cors',
                    body:JSON.stringify(obj)
                })
                    .then(async(resp)=>{
                        if(resp.ok) return resp.json()

                        let a = (await resp.json())
                        errorListener(a)
                        throw a
                    })
                    .catch((err)=>{
                        throw ([{code:"local.network",path:["*"]}]); // eslint-disable-line
                    }),
        },
        "2AF":{
            engage:()=>fetch(endpoint+'/settings/2AF/engage',{
                        method:'GET',
                        headers:{
                            "Content-Type":'application/json',
                            "Authorization" : window.authtoken 
                        },
                        mode:'cors'
                    })
                        .then(async(resp)=>{
                            if(resp.ok) return resp.json()

                            let a = (await resp.json())
                            errorListener(a)
                            throw a
                        })
                        .catch((err)=>{
                            throw ([{code:"local.network",path:["*"]}]); // eslint-disable-line
                        }),
            enable:(obj)=>fetch(endpoint+'/settings/2AF/enable',{
                        method:'POST',
                        headers:{
                            "Content-Type":'application/json',
                            "Authorization" : window.authtoken 
                        },
                        mode:'cors',
                        body:JSON.stringify(obj)
                    })
                        .then(async(resp)=>{
                            if(resp.ok) return resp.json()

                            let a = (await resp.json())
                            errorListener(a)
                            throw a
                        })
                        .catch((err)=>{
                            throw ([{code:"local.network",path:["*"]}]); // eslint-disable-line
                        }),
            disable:()=>fetch(endpoint+'/settings/2AF/disable',{
                        method:'GET',
                        headers:{
                            "Content-Type":'application/json',
                            "Authorization" : window.authtoken 
                        },
                        mode:'cors'
                    })
                        .then(async(resp)=>{
                            if(resp.ok) return resp.json()

                            let a = (await resp.json())
                            errorListener(a)
                            throw a
                        })
                        .catch((err)=>{
                            throw ([{code:"local.network",path:["*"]}]); // eslint-disable-line
                        }),
            get:()=>fetch(endpoint+'/settings/2AF',{
                        method:'GET',
                        headers:{
                            "Content-Type":'application/json',
                            "Authorization" : window.authtoken 
                        },
                        mode:'cors'
                    })
                        .then(async(resp)=>{
                            if(resp.ok) return resp.json()

                            let a = (await resp.json())
                            errorListener(a)
                            throw a
                        })
                        .catch((err)=>{
                            throw ([{code:"local.network",path:["*"]}]); // eslint-disable-line
                        })
        }
    },
    Event:{
        event:{
            list:()=>fetch(endpoint+'/events/event',{
                    method:'GET',
                    headers:{
                        "Content-Type":'application/json',
                        "Authorization" : window.authtoken 
                    },
                    mode:'cors'
                })
                    .then(async(resp)=>{
                        if(resp.ok) return resp.json()

                        let a = (await resp.json())
                        errorListener(a)
                        throw a
                    })
                    .catch((err)=>{
                        throw ([{code:"local.network",path:["*"]}]); // eslint-disable-line
                    })
        }
    },
    Parliament:{
        congressman:{
            list:(obj={})=>fetch(endpoint+'/datalake/parliament/congressman',{
                method:'POST',
                headers:{
                    "Content-Type":'application/json',
                    "Authorization" : window.authtoken 
                },
                body:JSON.stringify(obj),
                mode:'cors'
            })
                .then(async(resp)=>{
                    if(resp.ok) return resp.json()

                    let a = (await resp.json())
                    errorListener(a)
                    throw a
                })
                .catch((err)=>{
                    throw ([{code:"local.network",path:["*"]}]); // eslint-disable-line
                        }),
            
        }
    },
    CRM:{
        customer:{
            list:(page="")=>fetch(endpoint+'/crm/customer',{
                method:'GET',
                headers:{
                    "Content-Type":'application/json',
                    "Authorization" : window.authtoken 
                },
                mode:'cors'
            })
                .then(async(resp)=>{
                    if(resp.ok) return resp.json()

                    let a = (await resp.json())
                    errorListener(a)
                    throw a
                })
                .catch((err)=>{
                    throw ([{code:"local.network",path:["*"]}]); // eslint-disable-line
                }),
            get:(mail)=>fetch(endpoint+'/crm/customer/'+mail,{
                        method:'GET',
                        headers:{
                            "Content-Type":'application/json',
                            "Authorization" : window.authtoken 
                        },
                        mode:'cors'
                    })
                    .then(async(resp)=>{
                        if(resp.ok) return resp.json()

                        let a = (await resp.json())
                        errorListener(a)
                        throw a
                    })
                    .catch((err)=>{
                        throw ([{code:"local.network",path:["*"]}]); // eslint-disable-line
                    }),
            }
    },
    Esus:{
        trip:{
            list:()=>fetch(endpoint+'/esus/trip',{
                method:'GET',
                headers:{
                    "Content-Type":'application/json',
                    "Authorization" : window.authtoken 
                },
                mode:'cors'
            })
                .then(async(resp)=>{
                    if(resp.ok) return resp.json()
    
                    let a = (await resp.json())
                    errorListener(a)
                    throw a
                })
                .catch((err)=>{
                    throw ([{code:"local.network",path:["*"]}]); // eslint-disable-line
                }),
            get:(id)=>fetch(endpoint+'/esus/trip/'+id,{
                method:'GET',
                headers:{
                    "Content-Type":'application/json',
                    "Authorization" : window.authtoken 
                },
                mode:'cors'
            })
                .then(async(resp)=>{
                    if(resp.ok) return resp.json()
    
                    let a = (await resp.json())
                    errorListener(a)
                    throw a
                })
                .catch((err)=>{
                    throw ([{code:"local.network",path:["*"]}]); // eslint-disable-line
                }),
            step:{
                list:(trip)=>fetch(endpoint+'/esus/trip/'+trip+'/step',{
                    method:'GET',
                    headers:{
                        "Content-Type":'application/json',
                        "Authorization" : window.authtoken 
                    },
                    mode:'cors',
                    rejectUnhauthorized : false
                })
                    .then(async(resp)=>{
                        if(resp.ok) return resp.json()
        
                        let a = (await resp.json())
                        errorListener(a)
                        throw a
                    })
                    .catch((err)=>{
                        throw ([{code:"local.network",path:["*"]}]); // eslint-disable-line
                    }),
            }
        },
        info:{

        }
    },
    Live:{
        result:{
           get: fetch(endpoint+'/live/result/LEG/circo',{
                    method:'GET',
                    headers:{
                        "Content-Type":'application/json',
                        "Authorization" : window.authtoken 
                    },
                    mode:'cors',
                    timeout:40000
                })
                .then(async(resp)=>{
                    if(resp.ok) return resp.json()

                    let a = (await resp.json())
                    errorListener(a)
                    throw a
                })
                .catch((err)=>{
                    throw ([{code:"local.network",path:["*"]}]); // eslint-disable-line
                }),
        }
    }
        
}


