import React from 'react';
import { 
    ScrollView , 
    Navbar , 
    Viewport ,
    Form,
    Formgroup,
    Input,
    Button,
    Popup
} from '../../../UIKit/UIKit';
import { Formik } from 'formik';
import {API} from './../../../javascript/API';


import arrowBack from '../../../bundles/angle-left.png';
import iconPassword from '../../../bundles/password.png';
import LANG from '../../../Langs/en';

import iconSuccess from './../../../bundles/success-green.png';
import iconError from './../../../bundles/warning-red.png';

class Page_SettingsPassword extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            loading:false,
            error:false
        }
        this.submitCredentials = this.submitCredentials.bind(this)

    }


    submitCredentials(values){
        API.settings.password.update(values)
            .then((d)=>{
                 this.setState({
                    loading:false,
                    error:false,
                    success:true
                 })
            })
            .catch((err)=>{
                this.setState({
                    loading:false,
                    error:true,
                    success:false
                })
            })
    }

    closePopup(){

    }





    render(){
        return <Viewport override="true">
            <ScrollView more-padding-y="true" blend="less">
            </ScrollView>
            <Navbar title='Settings' subtitle='Password' left={[{
                icon:arrowBack,
                title:'Back',
                onClick:()=>window.history.back()
            }]} />
            <Formik
                initialValues={{ old: '', password: '' ,confirm:''}}
                onSubmit={this.submitCredentials}                
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    setFieldValue
                }) => (
                    <Form>
                        <Formgroup>
                            <Input type="password" name="old" placeholder="Current Password" error={errors.old} onChange={handleChange} value={values.old} onBlur={handleBlur} autocomplete="current-password"/>
                        </Formgroup>
                        <Formgroup>
                            <Input type="password" name="password" placeholder="New Password" error={errors.password} onChange={handleChange} value={values.password} onBlur={handleBlur} autocomplete="new-password" />
                        </Formgroup>
                        <Formgroup>
                            <Input type="password" name="confirm" placeholder="Confirm New Password" error={errors.confirm} onChange={handleChange} value={values.confirm} onBlur={handleBlur} autocomplete="new-password"/>
                        </Formgroup>
                        <div className='error'>{errors.main??''}</div>
                        <Formgroup size="1/2"/>
                        <Formgroup size="1/2">
                            <Button onClick={handleSubmit}>Change</Button>
                        </Formgroup>
                    </Form>
                )}
            </Formik>
            <Popup active={this.state.error} icon={iconError} level="error" title={"Error"} handleClose={()=>this.setState({loading:false,error:false,success:false})}description={'An error as occured'}/>
            <Popup active={this.state.success} icon={iconSuccess} level="success" title={"Notification"} handleClose={()=>this.setState({loading:false,error:false,success:false})} description={'Your password has been succesfully updated'}/>
        </Viewport>
    }
}

export default Page_SettingsPassword;