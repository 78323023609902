import React from "react";
import BottomBar from "./BottomBar";
import './NavMenu.css';
import ScrollView from "./ScrollView";
import logo1 from './Ressources/LogoLoading 1_WT@2x.png';
import logo2 from './Ressources/LogoLoading 2_WT@2x.png';

class NavMenu extends React.Component{
    constructor(props){
        super(props)
        this.state={
            active:this.props.selected
        }
        this.handleToggle = this.handleToggle.bind(this)
    }

    handleToggle(){
        this.setState({
            ...this.state,
            active:!this.state.active
        })
    }

    render = ()=>
    <div className="NavMenu" data-selected={this.state.active}>
        <ScrollView more-padding="true" more-blend="true" >
            {this.props.actions?.map((e,i)=>
                <div 
                    className="Action" 
                    role="button" 
                    style={{transitionDelay:(i*100)+"ms"}} 
                    onClick={()=>{window.location.href = e.href;this.forceUpdate();setTimeout(this.handleToggle,300)}} 
                    data-selected={((window.location.pathname+window.location.search+window.location.hash).startsWith(e.href)).toString()}
                >
                    <div className="indicator"></div>
                    <img src={e.icon} alt={e.title} />
                    <h3>{e.title}</h3>
                </div>
            )??null}
            
        </ScrollView>
        <BottomBar>
            {this.props.bottom?.map((e,i)=>
                <div 
                    className="Action"
                    role="button" 
                    style={{transitionDelay:(i*100)+"ms"}} 
                    onClick={()=>{window.location.href = e.href;this.forceUpdate();setTimeout(this.handleToggle,300)}} 
                    data-selected={((window.location.pathname+window.location.search+window.location.hash).startsWith(e.href)).toString()}
                >
                    <div className="indicator"></div>
                    <img src={e.icon} alt={e.title} />
                    <h3>{e.title}</h3>
                </div>
            )??null}
        </BottomBar>
        <NavMenuHamburger onClick={this.handleToggle}/>
    </div>
}

class NavMenuHamburger extends React.Component{
    shouldComponentUpdate(){
        return false;
    }
    render(){
       return <>
            <img className="hamburger" src={logo1} alt="Navigation Menu" role="button" onClick={this.props.onClick}/>
            <img className="hamburger" spin="true" src={logo2} alt="Navigation Menu" role="button" onClick={this.props.onClick}/>
        </>
    }
}

export default NavMenu;