import React from 'react';
import './Navbar.css';


class Navbar extends React.Component{
    render(){
        return (
            <div className="navbar">
                {Array.isArray(this.props.left) && <div className='navbutton' data-pos="left">
                    {this.props.left?.map((e)=><img className='button' role="button" onClick={e.onClick} src={e.icon} alt={e.name??"button"} />)}
                </div>}
                <div className='title'>
                    <h1 align={this.props.align??"left"}>{this.props.title}</h1>
                    {(this.props.subtitle!=null)?<h2>{this.props.subtitle}</h2>:null}
                </div>
                {Array.isArray(this.props.right) && <div className='navbutton' data-pos="right">
                    {this.props.right?.map((e)=><img className='button' role="button" style={{float:"right"}} onClick={e.onClick} src={e.icon} alt={e.name??"button"} />)}
                </div>}
                
            </div>
        );
    }
}

export default Navbar;