import React from "react";
import './ElectionResult.css'
import LANG from './../../src/Langs/en'

function ElectionResult(props){

    function numberWithSpaces(x) {
        if(isNaN(x)) x = 0;
        var parts = (x??0).toString().split(".");
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
        return parts.join(".");
    }
    if(props.data.nuance.shortname == 'VACANT'){
        return <></>
    }

    return <tr className="ElectionResult">
        
        <td className="index">{props.i+1}</td>
        <td className="LightBulbContainer"><div className="LightBulb" style={{background:props?.data?.nuance?.color}} ></div></td>
        <td className="Name">{props?.data?.nuance?.shortname} {props.data.status!=='NONE' && <div className="status" data={props.data.status}>{LANG.APP.LIVE.ELECTION.STATUS[props.data.status??'NONE']}</div>}</td>
        <td>{numberWithSpaces(props.data.votes??0)}</td>
        <td>{props.data.percent?.toFixed?.(2)??0}%</td>
        <td>{props.data.remaning??0}</td>
        <td>{numberWithSpaces(props.data.funding?.toFixed?.(2)??0)} € </td>
        <td>{props.data.seat}</td>
    </tr>
}

export default ElectionResult;