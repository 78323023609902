import {React , useContext} from "react";
import { useOutletContext } from "react-router-dom";
import { Viewport,ScrollView,Loading,Navbar, ProgressBar, VSpacer, ListItem , Error} from "../../UIKit/UIKit";
import ElectionCirco from "../../Kit/ElectionCirco";
import { SasContext , LoadingContext } from './../MainWindow';
import iconClose from './../../bundles/cross-small.png';

function Live_CircoPage(){
    const [data,setData] = useOutletContext();
    const {sas,setSas} = useContext(SasContext);

    function closeSas(){
        setSas({active:false,content:null})
    }

    function openSas(e){

        function badgeGenerator(w){
            let o = {}
            if(w==='AWAITING'){
                o = {
                    color:'black',
                    bg:'var(--info-color)',
                    text:'En Attente'
                }
            }
            else if(w==='ELECTED'){
                o={
                    color:'black',
                    bg:'var(--success-color)',
                    text:'Élu(e)'
                }
            }
            else if(w==='OVER'){
                o={
                    color:'black',
                    bg:'var(--error-color)',
                    text:'Éliminé(e)'
                }
            }
            else if(w==='SELECTED'){
                o={
                    color:'black',
                    bg:'var(--success-color)',
                    text:'Ballotage'
                }
            }

            return o;
        }
        setSas({
            active:true,
            content:<>
                <ScrollView padding-x="true">
                    <h2>Progress</h2>
                    <ProgressBar progress={e.progress} showText={true} />
                    <VSpacer/>
                    <h2>Candidats</h2>
                    {e?.candidats?.length ? e?.candidats?.map?.((k)=><ListItem title={k.name} subtitle={k.nuance.name+' ('+k.nuance.shortname+')'} lightbulbColor={k.nuance.color} progress={k.percent??false} showTextProgress={true} badge={[badgeGenerator(k.status)]} />) : <Error title='Error' subtitle={'Can\'t display this information'} noScreen={true}/>}
                    <VSpacer/>
                </ScrollView>
            <Navbar title={'Result'} subtitle={`${e.departementName}(${e.departement}) - ${e.circo}`} right={[{
                    icon:iconClose,
                    name:'close',
                    onClick:closeSas
                }]}/>

            </>
        })
    }


    return <Viewport>
        <ScrollView more-padding-y="true">
            { data?.circo?.map?.((e)=><ElectionCirco data={e} onClick={()=>openSas(e)}/>) }
        </ScrollView>
        
        <Navbar title='Live Results' subtitle='Sectors' />
    </Viewport>
}

export default Live_CircoPage;