import React from "react";
import './Form.css';

class Form extends React.Component{
    render(){
        return (
            <form onSubmit={this.props.onSubmit} >
                {this.props.children}
            </form>            
        );
    }
}

Form.defaultProps = {
    onSubmit: (event)=>{
        event.preventDefault();
    }
}
export default Form;