import React from "react";
import './Switch.css';

class Switch extends React.Component{

    constructor(props){
        super(props);
    }

    render(){
        return <input {...this.props} type="checkbox" className="Switch" />;
    }
}

export default Switch;