import React from "react";
import {Loading, Navbar,ScrollView,Error,Viewport} from "./../../../UIKit/UIKit";
import LANG from "./../../../Langs/en";
import ListItemExtension from "../../../Kit/ListItemExtension";
import {API} from "../../../javascript/sessionManager";


import arrowBack from '../../../bundles/angle-left.png';

class Page_SettingsExtensions extends React.Component{
    constructor(props){
        super(props);

        this.state = {
            loading:true,
            data:false,
            error:false
        }

        this.componentDidMount = this.componentDidMount.bind(this)
    }

    componentDidMount(){
        this.setState({
            ...this.state,
            loading:true
        });

        API.extension.list((d)=>{
            this.setState({
                loading:false,
                error:false,
                data:d
            })
            this.forceUpdate();
        })
    }

    

    render() {
        return (
            <Viewport override="true">
            
                <ScrollView  more-padding-y={"true"} blend={"less"} >
                    {(!this.state.loading&&!this.state.error)&&this.state.data?.map((e,i)=>{
                        return <ListItemExtension data={e} index={i}/>
                    })}
                    <Loading active={(this.state.loading&&!this.state.error)} title={LANG.LOADING.RETRIVING} subtitle={LANG.LOADING.COLLECTING}/>
                    {(!this.state.loading&&this.state.error)&&<Error title={this.state.error.TITLE} subtitle={this.state.error.SUBTITLE}/>}

                </ScrollView>
                <Navbar title={LANG.APP.EXTENSION.NAVBAR.TITLE} subtitle={LANG.APP.EXTENSION.NAVBAR.SUBTITLE} left={[{
                icon:arrowBack,
                title:'Back',
                onClick:()=>window.history.back()
            }]} />
             </Viewport>
        );
    }
}

export default Page_SettingsExtensions;