import {React , useContext} from "react";
import { BottomBar, Button, Formgroup, ListItem, ListSeparator, Loading, Navbar, ScrollView ,Switch,Viewport} from "../../UIKit/UIKit";

import {API} from "../../javascript/API";


import iconSecurity from "../../bundles/lock.png";
import iconProfil   from "../../bundles/id.png";
import iconNotification from '../../bundles/bell.png'
import iconExtension from '../../bundles/layers.png'
import iconPassword from './../../bundles/password.png';
import iconKey from './../../bundles/key.png';
import iconSession from './../../bundles/devices.png';
import iconClose from "./../../bundles/cross-small.png";
import iconLock from "./../../bundles/lock.png";

import { SasContext , LoadingContext } from './../MainWindow';

function Page_SettingsAccount(props){
    const {sas,setSas} = useContext(SasContext);
    const {loading,setLoading} = useContext(LoadingContext);


    function closeSas(){
        setSas({
            active:false,
            content:null
        })
    }

    function logout(){
        closeSas();
        setLoading({
            title:"Closing ...",
            subtitle:"Disconecting your session"
        });
        API.deauth()
            .then((d)=>{
                setLoading(false)
            })
            .catch(err=>{
                setLoading(false)
            })
    }
    function openSas(){
        setSas({
            active:true,
            content:<>
                <Navbar level="error" title="Close Session" align="center" right={[{
                    icon:iconClose,
                    title:'Close',
                    onClick:closeSas
                }]}/>
                <p>
                    Confirmation required for closing your sesssion
                </p>
                <Formgroup size="1/2"><Button onClick={closeSas}>Cancel</Button></Formgroup>
                <Formgroup size="1/2"><Button level="error" onClick={logout}>Disconnect</Button></Formgroup>
            </>

        })
    }

    return <Viewport>
            <ScrollView >
                <ListSeparator>Security</ListSeparator>
                <ListItem
                    title='Password'
                    subtitle='set your password'
                    icon={iconPassword}
                    onClick={()=>window.location.href = '/#/settings/security/password'}
                />
                <ListItem
                    title='2 Authentication Factor'
                    subtitle='Manage your 2AF Key'
                    icon={iconLock}
                    onClick={()=>window.location.href = '/#/settings/security/2AF'}
                />
                <ListItem
                    disabled={true}
                    title='Secure Key'
                    subtitle='Manage your AuthKeys'
                    icon={iconKey}
                    onClick={()=>window.location.href = '/#/settings/security/webauthn'}
                />
                <ListItem
                    title='Sessions'
                    subtitle='Verify which device is connected'
                    icon={iconSession}
                    onClick={()=>window.location.href = '/#/settings/security/sessions'}
                />
                <ListSeparator>Profile information</ListSeparator>
                <ListItem
                    title='Profile information'
                    subtitle='edit your profile'
                    icon={iconProfil}
                    onClick={()=>window.location.href = '/#/settings/account/profile'}
                />
                 <ListSeparator>Settings</ListSeparator>
                <ListItem
                    title='Notifications'
                    subtitle='manage your notifications'
                    icon={iconNotification}
                    onClick={()=>console.log('fff')}
                />
                <ListItem
                    title='Extensions'
                    subtitle='Download extensions to accelerate requests'
                    icon={iconExtension}
                    onClick={()=>window.location.href = '/#/settings/app/extension'}
                />
                
                <ListItem
                    level="error"
                    title='Log Out'
                    onClick={()=>{
                        openSas()
                    }}
                />
            </ScrollView>
            <Navbar title="Settings" subtitle="Account" />
        </Viewport>
}


export default Page_SettingsAccount;