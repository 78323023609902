import React from "react";
import './Error.css';

class Error extends React.Component{


    render(){
        if(this.props.noScreen){
            return<div className="ErrorBlock"  level={this.props.level} >
                <div className="ErrorContent"  >
                    <div></div>
                    <h2>{this.props.title}</h2>
                    <h3>{this.props.subtitle}</h3>
                </div>
            </div>
        }
        else{
            return <div className="ErrorScreen"  >
                <div className="ErrorBlock" level={this.props.level}>
                    <div className="ErrorContent">
                        <div></div>
                        <h2>{this.props.title}</h2>
                        <h3>{this.props.subtitle}</h3>
                    </div>
                </div>
            </div>
        }
        
    }
}

export default Error;