import React from 'react';
import './ListItem.css';
import arrow from './../../bundles/angle-right.png';
import dot3 from './../../bundles/dot-option.png';
import PropTypes from 'prop-types'; 
import ProgressBar from './ProgressBar';





class ListItem extends React.Component{

    static propTypes = {
        title:PropTypes.string,
        subtitle:PropTypes.string,
        description:PropTypes.string,
        icon:PropTypes.any,
        thumbnail:PropTypes.any,
        disabled:PropTypes.bool,
        active:PropTypes.bool,
        onClick:PropTypes.func,
        onContextMenu:PropTypes.func,
        level:PropTypes.oneOf(['info','error','warn','success',null,'none'])
    }

/**


 * @params {string} level
 * @params {any} icon
 * @parmas {any} thumbnail
 * @params {any} children
 * @params {string} title
 * @params {string} subtitle
 * @params {string} description
 * @params {bool} disabled
 * @params {bool} active
 * @params {function} onContextMenu
 * @params {function} onClick
 */
    constructor(props){
        super(props)
    }
    render(){
        return <div className='ListItem' disabled={this.props.disabled} onClick={this.props.onClick} clickable={((this.props?.onClick??null)!==null || (this.props.clickable??false)).toString()} level={this.props.level??'none'} data-selected={this.props?.selected??false}>
            <div className='fill'></div>
                {this.props.lightbulbColor && <div className='LightBulbMain' style={{background:this.props.lightbulbColor}}></div>}
                {this.props.thumbnail &&  <img className='thumbnail' src={this.props.thumbnail} alt='Thumbnail'/>}
                {this.props.icon && !this.props?.level && <img className='icon' src={this.props.icon} alt='icon' />}
                {((this.props?.active??null) !== null) && <div className='LightBulb' active={this.props?.active?.toString()}></div>}
            <div className='text-wrapper'>
                {this.props.title && <h2>{this.props.title} {this?.props?.badge?.map?.(w=><div className='badge' style={{color:w.color,background:w.bg}}>{w.text}</div>)}</h2>}
                {this.props.subtitle &&  <h3>{this.props.subtitle}</h3>}
                {Boolean(this.props.progress) && <ProgressBar progress={this.props.progress} color={this.props.progressColor} showText={this.props.showTextProgress} />}
                {this.props.description &&  <p>{this.props.description}</p>}
                
                
            </div>
            {this.props.children}
            {this.props.onContextMenu && !this.props?.level && <img class='options' style={{transform:'scale(1.3)'}}src={dot3} alt='Arrow of list item'/>}
            {this.props.onClick && !this.props?.level && <img class='options' src={arrow} alt='Arrow of list item'/>}
        </div>
    }
}
export default ListItem;