import React from "react";
import { motion, AnimatePresence } from "framer-motion"
import './SmallActionSheet.css';
import './Fade.css';


class SmallActionSheet extends React.Component{
    render(){
        return (
            <AnimatePresence>
                {this.props.active && 
                    <motion.div 
                        key="fade"

                        className="fade"
                        onClick={this.props.onClick} 
                        
                        initial={{ 
                            opacity:0
                        }}
                        animate={{ 
                            opacity:1
                        }}
                        exit={{ 
                            opacity:0
                        }}
                        transition={{ duration: 0.2 ,ease:"easeIn"}}
                    >
                        <motion.div 
                        key="actionsheet"
                        className="SmallActionSheet" 
                        initial={{ 
                            y:window.innerWidth<600?'100%':'40%',
                            x:'-50%'
                        }}
                        animate={{ 
                            y:window.innerWidth<600?'0%':'50%',
                            x:'-50%'
                        }}
                        exit={{ 
                            y:window.innerWidth<600?'100%':'40%',
                            x:'-50%'
                        }}
                        transition={{ duration: 0.2 ,ease:"easeIn"}}
                        onClick={(event)=>{event.stopPropagation()}}
                        >
                            {this.props.children}
                        </motion.div> 
                    </motion.div>
                }
            </AnimatePresence>
            
        );
    }
}

export default SmallActionSheet;