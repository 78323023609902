import React from 'react';
import {Navbar,BottomBar,ScrollView,Button,Formgroup,Loading,Error,Viewport} from './../UIKit/UIKit';
import ListItemOrganisation from './../Kit/ListItemOrganisation'
import LANG from '../Langs/en';
import {API} from './../javascript/API';

class Page_Organisation extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            loading:true,
            data:[]
        }

        this.handleClick = this.handleClick.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.loadData = this.loadData.bind(this);

    }

    componentDidMount(){
        this.loadData()
    }
    
    loadData(){
        API.access.list()
            .then((data)=>{
                this.setState({
                    loading:false,
                    error:false,
                    data:data
                })
            })
            .catch((errors)=>{
                this.setState({
                    error:{
                        title:LANG.ERROR[errors[0].code].TITLE,
                        subtitle:LANG.ERROR[errors[0].code].SUBTITLE,
                    },
                    loading:false
                })
            })
    }

    handleClick(e){
        this.setState({
            ...this.state,
            data:{
                content:this.state.data?.content??[],
                selected:e._id 
            }
        })
    }

    handleSubmit(){
        this.setState({
            ...this.state,
            mainLoading:true
        })
        API.access.set(this.state.data.selected)
            .then((data)=>{
                this.setState({
                    ...this.state,
                    mainLoading:false
                })
                window.location.href = '/#/';
            })
            .catch((err)=>{
                this.setState({
                    ...this.state,
                    mainLoading:false
                })
            });
    }


    render(){
        return(
            <Viewport>
                <ScrollView more-padding-y={"true"} blend={"less"} >
                    {!this.state.loading && !this.state.error && this.state.data.content.map((element,i) => {
                        return <ListItemOrganisation index={i} onClick={this.handleClick} selected={this.state.data.selected===element._id}  data={element} />
                    })}
                    {this.state.error && <Error title={this.state.error.title} subtitle={this.state.error.subtitle} level="error" />}
                    <Loading title={LANG.LOADING.RETRIVING} progress={null} active={this.state.loading} subtitle={LANG.LOADING.COLLECTING} />
                </ScrollView>
                <Navbar title={LANG.NAVBAR.ORGANISATION_SELECT.TITLE} subtitle={LANG.NAVBAR.ORGANISATION_SELECT.SUBTITLE} />
                <BottomBar>
                    <Formgroup>
                        <Button disabled={ (this.state.loading||this.state.mainLoading) || this.state.data.selected == null } onClick={this.handleSubmit} >{LANG.BUTTON.CONNECT}</Button>
                    </Formgroup>
                </BottomBar>
                <Loading title={LANG.LOADING.CONNECTING} progress={null} active={this.state.mainLoading} subtitle={LANG.LOADING.SETTING_ACCESS}/>
            </Viewport> 
        )
    }
}


export default Page_Organisation;
  