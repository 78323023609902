import React from "react";
import { Error, Loading, NavBottomBar ,Navbar, ScrollView, Viewport,ListItem,Popup, ViewportModal } from "../../UIKit/UIKit";

import { API } from "../../javascript/API";
import LANG from "../../Langs/en";
import arrowBack from '../../bundles/angle-left.png';
import iconPlus from '../../bundles/plus.png';
import iconX from '../../bundles/cross-small.png';



class Page_EsusTrip extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            data:[],
            loading:false,
            error:false
        }
    }


    componentDidMount = ()=>{
        this.setState({
            error:false,
            loading:true,
            data:[],
        });

        API.Esus.trip.list().then((data)=>{
            console.log(data);
            this.setState({
                ...this.state,
                loading:false,
                error:false,
                data:data
            })
        }).catch((err)=>{
            this.setState({
                ...this.state,
                loading:false,
                error:err
            })
            
        })
    }
    dateDiff=function (date1, date2){
        var diff = {}                           // Initialisation du retour
        var tmp = date2 - date1;
     
        tmp = Math.floor(tmp/1000);             // Nombre de secondes entre les 2 dates
        diff.sec = tmp % 60;                    // Extraction du nombre de secondes
     
        tmp = Math.floor((tmp-diff.sec)/60);    // Nombre de minutes (partie entière)
        diff.min = tmp % 60;                    // Extraction du nombre de minutes
     
        tmp = Math.floor((tmp-diff.min)/60);    // Nombre d'heures (entières)
        diff.hour = tmp % 24;                   // Extraction du nombre d'heures
         
        tmp = Math.floor((tmp-diff.hour)/24);   // Nombre de jours restants
        diff.day = tmp;
         
        return diff;
    }


    render = ()=><Viewport>
        <ScrollView more-padding-y={"true"}>
            {this.state.loading && <Loading title={this.state.loading.title} subtitle={this.state.loading.subtitle}/>}
            {this.state.error && <Error title={this.state.error.title} subtitle={this.state.error.subtitle}/>}
            {!this.state.loading && !this.state.error && this.state.data?.map?.((e)=>{console.log(e); return <ListItem title={e.name} subtitle={(new Date(e.start)).toLocaleString() + " / "+(this.dateDiff(new Date(e.end),new Date(e.start)).day)+" day" } onClick={()=>null}/>})}
        </ScrollView>
        <Navbar title='Esus' subtitle='Trip' right={[{
            icon:iconPlus,
            title:'Create a trip',
            onClick:()=>window.history.back()
        }]} />
    </Viewport>
    
}

export default Page_EsusTrip;