import React, {  useState , createContext } from "react";
import { NavMenu, Viewport ,SmallActionSheet ,Loading} from "../UIKit/UIKit";
import { AnimatePresence } from "framer-motion";

import {
    HashRouter as Router,
    Routes,
    Route,
    useParams,
    useNavigate,
    Redirect
} from 'react-router-dom';



import iconStats from "./../bundles/stats.png";
import iconEsus from "./../bundles/passport.png";
import iconParliament from './../bundles/parliament.png';
import iconTask from './../bundles/checklist.png';
import iconPhotos from './../bundles/images.png';
import iconDocuments from './../bundles/document.png';
import iconSettings from './../bundles/settings.png';
import iconCalendar from './../bundles/calendar.png';
import iconEvent from './../bundles/megaphone.png';
import iconWeb from './../bundles/globe.png';
import iconLink from './../bundles/qrcode.png';
import iconContacts from './../bundles/team.png';
import iconSocial from './../bundles/hashtag.png';
import iconLiveVote from './../bundles/box-ballot.png';


import PageAuth from "./Auth.page";
import Page_SettingsAccount from "./Settings/SettingsAccount.page";
import Page_SettingsMain from "./Settings/Settings.page";
import Page_SettingsOrganisation from "./Settings/SettingsOrganisation.page";
import Page_SettingsPassword from "./Settings/SubSettings/SettingsPassword.page";
import Page_SettingsExtensions from './Settings/SubSettings/SettingsExtensions.page';
import Page_Organisation from "./Organisation.page";
import Page_SettingsSessions from "./Settings/SubSettings/SettingsSessions.page";
import Page_Settings2AF from "./Settings/SubSettings/Settings2AF.page";
import Page_SettingsProfile from "./Settings/SubSettings/SettingsProfile.page";
import Page_EventsMain from "./Events/Events.page";
import Page_CRMDashboard from "./CRM/Dashboard.page";
import Page_EsusTrip from "./Esus/EsusTrips.page";
import Page_SocialPost from "./Social/SocialPost.page";
import Page_Live from "./Live/live.page";
import Live_CircoPage from "./Live/circo.page";
import Live_GlobalPage from "./Live/global.page";
import Live_MapPage from "./Live/map.page";


export const SasContext = createContext({
    active:false,
    content:null
});

export const LoadingContext  = createContext(false)

const MainWindow = ()=>{  

        const [sas,setSas] = useState({
            active:false,
            content:null
        });

        const [loading,setLoading] = useState({
            active:false
        })

        return (
            <>
                <LoadingContext.Provider value={{loading,setLoading}}>

            
                    <SasContext.Provider value={{sas,setSas}} >
                    { window.authtoken !== false?
                        <Viewport>
                            <NavMenu selected={false} 
                                bottom={
                                    [
                                        {
                                            icon:iconSettings,
                                            title:"Settings",
                                            href:"/#/settings"
                                        }
                                    ]
                                }
                                actions={
                                    [
                                        {
                                            icon:iconStats,
                                            title:"Statistics",
                                            href:"/#/mapstats"
                                        },
                                        {
                                            icon:iconEsus,
                                            title:"Esus",
                                            href:"/#/esus"
                                        },
                                        {
                                            icon:iconParliament,
                                            title:"Parliament",
                                            href:"/#/parliament"
                                        },
                                        {
                                            icon:iconTask,
                                            title:"Task",
                                            href:"/#/task"
                                        },
                                        {
                                            icon:iconPhotos,
                                            title:"Media Library",
                                            href:"/#/media"
                                        },
                                        {
                                            icon:iconDocuments,
                                            title:"Interparliament",
                                            href:"/#/cloud"
                                        },
                                        {
                                            icon:iconCalendar,
                                            title:"Calendar",
                                            href:"/#/calendar"
                                        },
                                        {
                                            icon:iconEvent,
                                            title:"Events",
                                            href:"/#/events"
                                        },
                                        {
                                            icon:iconWeb,
                                            title:"Websites",
                                            href:"/#/website"
                                        },
                                        {
                                            icon:iconLink,
                                            title:"Link",
                                            href:"/#/links"
                                        },
                                        {
                                            icon:iconContacts,
                                            title:"CRM",
                                            href:"/#/crm"
                                        },
                                        {
                                            icon:iconSocial,
                                            title:"Social",
                                            href:"/#/social"
                                        },
                                        {
                                            icon:iconLiveVote,
                                            title:"Live Election",
                                            href:"/#/live"
                                        }
                                    ]
                                }
                            />
                                <Router>
                                    <AnimatePresence>
                                        <Routes>
                                            
                                            <Route path='/settings' element={<Page_SettingsMain/>}>
                                                <Route path="/settings/account" element={<Page_SettingsAccount/>}/> 
                                                <Route path="/settings/organisation" element={<Page_SettingsOrganisation/>}/> 
                                            </Route>
                                            <Route path='/settings/app/extension' element={<Page_SettingsExtensions/>}/>
                                            <Route path='/settings/security/password' element={<Page_SettingsPassword/>}/>
                                            <Route path='/settings/security/2AF' element={<Page_Settings2AF/>}/>
                                            <Route path='/settings/security/sessions' element={<Page_SettingsSessions/>}/>
                                            <Route path='/settings/account/profile' element={<Page_SettingsProfile/>}/>
                                            <Route path="/organisation" element={<Page_Organisation/>}/>


                                            <Route path="/events" element={<Page_EventsMain/>}/>
                                            <Route path="/events" element={<WithRouter/>}/>
                                            <Route path="/crm" element={<Page_CRMDashboard/>}/>
                                            <Route path="/esus" element={<Page_EsusTrip/>}/>
                                            <Route path="/social" element={<Page_SocialPost/>}/>
                                            <Route path="/live" element={<Page_Live/>}>
                                                <Route path="/live/global" element={<Live_GlobalPage/>}/> 
                                                <Route path="/live/sector" element={<Live_CircoPage/>}/> 
                                                <Route path="/live/map" element={<Live_MapPage/>}/> 
                                            </Route>
                                        </Routes>
                                    </AnimatePresence>
                                </Router>
                        </Viewport>:<PageAuth/>
                        }
                        <SmallActionSheet active={sas.active}>
                            {sas.content}
                        </SmallActionSheet>
                        <Loading title={loading?.title} subtitle={loading.subtitle} active={ loading?.title!=null }/>
                    </SasContext.Provider>
                </LoadingContext.Provider>
            </>
        )
}

export default MainWindow;

const WithRouter = props => {
    const params = useParams();
    const navigate = useNavigate();
    const WrappedComponent = props.element;
    console.log(params);
    return (
      <WrappedComponent {...props} {...params} navigate={navigate}/>
    );
  };