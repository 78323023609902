import React from "react";
import { Error, Loading, NavBottomBar ,Navbar, ScrollView, Viewport,ListItem } from "../../UIKit/UIKit";

import { API } from "../../javascript/API";
import LANG from "../../Langs/en";
import arrowBack from '../../bundles/angle-left.png';
import iconPlus from '../../bundles/plus.png';



class Page_CRMDashboard extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            data:[],
            loading:false,
            error:false
        }
    }


    componentDidMount = ()=>{
        this.setState({
            error:false,
            loading:true,
            data:[]
        });

        API.CRM.customer.list().then((data)=>{
            console.log(data);
            this.setState({
                ...this.state,
                loading:false,
                error:false,
                data:data
            })
        }).catch((err)=>{
            this.setState({
                ...this.state,
                loading:false,
                error:err
            })
            
        })
    }


    render = ()=><Viewport>
        <ScrollView more-padding-y={"true"}>
            {this.state.loading && <Loading title={this.state.loading.title} subtitle={this.state.loading.subtitle}/>}
            {this.state.error && <Error title={this.state.error.title} subtitle={this.state.error.subtitle}/>}
            {!this.state.loading && !this.state.error && this.state.data?.map?.((e)=>{console.log(e); return <ListItem title={e.firstName+ ' '+e.lastName.toUpperCase()} subtitle={e._id.mail} onClick={()=>null}/>})}
        </ScrollView>
        <Navbar title='CRM' subtitle='All Customer' right={[{
            icon:iconPlus,
            title:'Add a new customer',
            onClick:()=>window.history.back()
        }]} />
    </Viewport>
}

export default Page_CRMDashboard;