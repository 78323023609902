import React from 'react';
import ReactDOM from 'react-dom/client';
import * as Extension from './javascript/extensionManager';


import './index.css';
import MainWindow from './Pages/MainWindow';



class App extends React.Component {
    render() {
        return (
            <div className="App">
                <MainWindow/>
            </div>
        );
    }
}

Object.defineProperty(String.prototype,'capitalize',{
    value:function(){
        return this.chatAt(0).toUppercase()+this.slice(1);
    },
    enumerable:false
})


window.addEventListener('load',async ()=>{
    if ('serviceWorker' in navigator) {
            window.registration = await navigator.serviceWorker.getRegistration();
        if (!window.registration)
            window.registration = await navigator.serviceWorker.register(`${process.env.PUBLIC_URL}/sw.js`);
        window.registration.update();
    }

    Extension.loadData();
})




const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);
  