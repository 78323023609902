import React from "react";
import './Button.css';

class Button extends React.Component{
    render(){
        return (this.props.div?
            <div role="button" className={"button "+this.props.class}  level={this.props.level} data-size={this.props.size} onClick={this.props.onClick}  disabled={this.props.disabled} >{this.props.children}</div>:
            <button type={"button"} level={this.props.level} data-size={this.props.size} onClick={this.props.onClick} class={this.props.class} disabled={this.props.disabled} >{this.props.children}</button>
        );
    }
}

Button.defaultProps={
    disabled:false,
    level:'none',
    onClick:()=>null,
    children:'OK',
    size:'normal'
}

export default Button;