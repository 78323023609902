import React from "react";
import "./ListItemEvent.css";
import moment from "moment-timezone";
import { DateTime } from "luxon";

import iconDownload from './../bundles/arrow-small-down.png';
import iconArrow from './../bundles/angle-right.png';
import iconCheck from './../bundles/check.png';

import tz_lookup from "tz-lookup";


class ListItemEvent extends React.Component{

    constructor(props){
        super(props)
    }

    render(){
        return (
            <div className="ListItemEvent" style={{animationDelay:((this.props.index+2)*150)+'ms'}} {...this.props} >
                <div className="fill"></div>
                <h2>{this.props.data.title??'Error'}</h2>
                <h3>{this.props.data.location} - {DateTime.fromSeconds(this.props.data.start).setZone(tz_lookup(...this.props.data.geopoint.split(','))).toFormat('dd/MM/yy HH:mm (z)')}</h3>
                <img className="icon" src={iconArrow}/>
            </div>
        )
    }
}

export default ListItemEvent;