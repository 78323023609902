import React from 'react';
import './Viewport.css';
import { motion } from "framer-motion";

class Viewport extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            id:Math.random()*1000
        }
    }


    render(){

        let off = {
            opacity:0,
            scale:1,
            x:'10%'
        };
        return (
            <motion.div
                key={this.state.id}
                className='viewport'
                override={(this.props?.override??false).toString()}
                initial={this.props.override?off:{
                    opacity:'0 !important',
                    scale:0.98,
                    x:0
                }}

                animate={{
                    opacity:1,
                    scale:1,
                    x:0
                }}

                exit={this.props.override?off:{
                    opacity:0,
                    scale:0.98
                }}

                transition={{ duration:0.2 ,type:"ease"}}
            >
                {this.props.children}
            </motion.div>
        );
    }
}

export default Viewport;