import React from "react";
import { useOutletContext } from "react-router-dom";
import { Viewport,ScrollView,Navbar,Form, ProgressBar, VSpacer,Error, Parliament } from "../../UIKit/UIKit";
import ElectionCirco from "../../Kit/ElectionCirco";
import ElectionResult from "../../Kit/ElectionResult";
import LANG from './../../Langs/en'

function Live_GlobalPage(){
    const [data,setData] = useOutletContext();
    return <Viewport>
        <ScrollView more-padding-y="true">
            <Form>
                <ProgressBar progress={data?.global?.progress??false} showText={true} />
                <VSpacer/>
                <Parliament data={data?.global?.seat.map?.((s)=>[s.nuance.name,s.seat,s.nuance.color,s.nuance.shortname])}/>
                <VSpacer/>
                <table style={{width:'100%'}}>
                    <thead>
                        <th>#</th>
                        <th>Color</th>
                        <th>Party</th>
                        <th>Votes</th>
                        <th>Percent</th>
                        <th>Candidats</th>
                        <th>Fund</th>
                        <th>Seats</th>
                    </thead>
                    <tbody>
                    {data?.global?.seat?.map?.((e,i)=><ElectionResult data={e} i={i}/>)}
                    </tbody>
                </table>
                <VSpacer/>
                <h2>Duel</h2>
                {data?.global?.duel?.length > 0 ? data?.global?.duel?.map?.( (k)=><ElectionCirco fight={k}/> ) : <Error noScreen={true} title={LANG.ERROR.INFO_CURRENTLY_NA.TITLE} level={'warn'} subtitle={LANG.ERROR.INFO_CURRENTLY_NA.SUBTITLE}/>}
                <VSpacer/>
                <h2>Triangulaire</h2>
                {data?.global?.truel?.length > 0 ? data?.global?.truel?.map?.( (k)=><ElectionCirco fight={k}/> ) : <Error noScreen={true} title={LANG.ERROR.INFO_CURRENTLY_NA.TITLE} level={'warn'} subtitle={LANG.ERROR.INFO_CURRENTLY_NA.SUBTITLE}/>}
                <VSpacer/>
            </Form>
        </ScrollView>
        
        <Navbar title='Live Results' subtitle='Parliament' />
    </Viewport>
}

export default Live_GlobalPage;