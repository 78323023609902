import {React,useContext,useState} from "react";
import {Viewport,BottomBar,Navbar,Formgroup,Form,Button,Input,Keypad,Loading} from './../UIKit/UIKit';
import {API} from './../javascript/API';
import { Formik } from "formik";
import { AnimatePresence , motion } from "framer-motion";
import {useNavigate} from "react-router-dom"

import logotext from "./../bundles/LogoText.png";
import { LoadingContext } from "./MainWindow";


const PageAuth = (props)=>{

    const [state,setState] = useState({OTPscreen:false,loading:{active:false}});
    const {loading,setLoading} = useContext(LoadingContext);

    function displayOTPInput(){
        console.log('2FA Required');
        setState({
            ...state,
            OTPscreen:true,
            loading:{
                ...state.loading,
                active:false
            }
        });
    }

    function hideOTPInput(){
        setState({
            ...state,
            loading:{
                ...state.loading,
                active:false
            },
            OTPscreen:false
        });
    }


    function submitCredentials(value,a){
        let setSubmitting = a.setSubmitting;
        console.log(value,a);
        value.code !== ''?
             setLoading({title:'Authentication',subtitle:'Validation of OTP Code'})
            :setLoading({title:'Authentication',subtitle:'Checking credentials'});
        API.auth(value)
            .then((data)=>{
                console.log('route')
                setSubmitting(false);
                window.location.href = "/#/organisation";
                setLoading(false);
                
            })
            .catch((exceptions)=>{      
                console.log('exception',exceptions); 
                if(exceptions[0]?.code==='auth.totp_required'){
                    console.log('displayOTP');
                    setLoading(false)
                    setState({
                        ...state,
                        OTPscreen:true
                    })
                    displayOTPInput();
                }
                else if(exceptions[0]?.code==='auth.invalid_credentials'){
                    setLoading(false);
                    a.setErrors({
                        id:' ',
                        pass:' ',
                        main:'Wrong Credentials'
                    })
                    a.setFieldValue('pass','');
                }
                else if(exceptions[0]?.code==='auth.totp_invalid'){
                    setLoading(false);
                    a.setErrors({
                        id:' ',
                        pass:' ',
                        main:'',
                        code:'Invalid Code'
                    })
                    a.setFieldValue('totp',null);
                    setLoading(false);
                }else{
                    setLoading(false);
                }
            
                setSubmitting(false);
            })
    }

    

    function getOTPInput(){
        console.log('otp input get');
        return state.OTPscreen;
    }

    
        return (
            <>
                <Navbar align="center" title={<img src={logotext} className="logotext" alt="Republica Logo"/>} />
                <Formik
                    initialValues={{ id: '', pass: '' ,code:''}}
                    onSubmit={submitCredentials}                
                >
                    {({
                       values,
                       errors,
                       touched,
                       handleChange,
                       handleBlur,
                       handleSubmit,
                       isSubmitting,
                       setFieldValue
                    }) => (
                        <Form>
                            <Formgroup>
                                <Input type="text" name="id" placeholder="Login" error={errors.id} onChange={handleChange} value={values.id} onBlur={handleBlur} autocomplete="username,email" />
                            </Formgroup>
                            <Formgroup>
                                <Input type="password" name="pass" placeholder="Password" error={errors.pass} onChange={handleChange} value={values.pass} onBlur={handleBlur} autocomplete="current-password"/>
                            </Formgroup>
                            <div className='error'>{errors.main??''}</div>
                            <BottomBar>
                                <Formgroup>
                                    <Button onClick={handleSubmit}>Login</Button>
                                </Formgroup>
                            </BottomBar>
                            <AnimatePresence>
                                {state.OTPscreen &&
                                    <motion.div
                                        key="OTPScreen"
                                        initial={{ 
                                            opacity:0
                                        }}
                                        animate={{ 
                                            opacity:1
                                        }}
                                        exit={{ 
                                            opacity:0
                                        }}
                                        transition={{ 
                                            duration: 0.2 ,
                                            ease:"easeIn"
                                        }}
                                    >
                                        <Viewport>
                                            <Navbar title="Security" subtitle="Enter your 2FA code" />
                                            <Keypad handleChange={handleChange} handleClose={hideOTPInput} name="totp" error={errors.totp} setFieldValue={setFieldValue} value={values.totp} handleSubmit={handleSubmit} autocomplete="one-time-code"/>
                                        </Viewport>
                                    </motion.div>
                                }
                            </AnimatePresence>
                        </Form>
                    )}
                </Formik>
            </>
        );
}

export default PageAuth;
