import React from "react";
import './Keypad.css';
import './Input.css';
import deleteKey from "./Ressources/delete-key.png";

class Keypad extends React.Component{
    constructor(props){
        super(props);

        this.clickHandler = this.clickHandler.bind(this);
        this.deleteHandler = this.deleteHandler.bind(this);
    }

    clickHandler = (event)=>{
        let tmpVal = this.props.value.length;
        if(this.props.value.length<6){
            let val = event.target.innerHTML;
            this.props.setFieldValue(this.props.name,this.props.value + val)
            tmpVal++;
        }
        
        if(tmpVal>=6){
            this.props.handleSubmit();
        }   
    }

    deleteHandler = ()=>{
        this.props.setFieldValue(this.props.name,this.props.value.substring(0,this.props.value.length-1))
    }

    render(){
        return (
            <div className="keypad-container">
                <div className="keypad" >
                    <div className="input" style={{paddingBottom:'50px'}}>
                        <input value={this.props.value} name={this.props.name}  level={this.props.error!=''&&this.props.error!=null?'error':null} onChange={this.props.handleChange} style={{marginLeft:'auto',marginRight:'auto',fontSize:'30px',textAlign:'center',width:'7.5ch'}} placeholder=' ' autoComplete={this.props.autocomplete} pattern="[0-9]{6}" maxLength="6"/>
                        <div className="error" style={{width:'100%',textAlign:'center',left:0,top:'50px'}}>{this.props.error}</div>
                    </div>
                    <InnerKeypad clickHandler={this.clickHandler} handleClose={this.props.handleClose} handleSubmit={this.props.handleSubmit} deleteHandler={this.deleteHandler} />
                </div>
            </div>
                        
        );
    }
}

Keypad.defaultProps = {

}

class InnerKeypad extends React.Component{
    constructor(props){
        super(props);
        console.log(props)
    }

    shouldComponentUpdate(nextState,nexprops){
        return false;
    }    
    render(){
        return (<>
            <div className="key" onClick={this.props.clickHandler} >1</div>
            <div className="key" onClick={this.props.clickHandler} >2</div>
            <div className="key" onClick={this.props.clickHandler} >3</div> 
            <div className="key" onClick={this.props.clickHandler} >4</div>
            <div className="key" onClick={this.props.clickHandler} >5</div>
            <div className="key" onClick={this.props.clickHandler} >6</div>
            <div className="key" onClick={this.props.clickHandler} >7</div>
            <div className="key" onClick={this.props.clickHandler} >8</div>
            <div className="key" onClick={this.props.clickHandler} >9</div>
            {this.props.hanbleClose && <div className="key" onClick={this.props.handleClose}  ><span style={{fontSize:'14px',verticalAlign:'middle',textAlign:'center'}} >Close</span></div>}
            {!this.props.handleClose && <div className="key" onClick={this.props.handleSubmit}  ><span style={{fontSize:'14px',verticalAlign:'middle',textAlign:'center'}} >OK</span></div>}
            <div className="key" onClick={this.props.clickHandler} >0</div>
            <div className="key" onClick={this.props.deleteHandler}><img src={deleteKey} alt="Delete Button"/></div>
        </>)
    }

}
export default Keypad;