import React from 'react';
import './ViewportModal.css';
import './Fade.css';
import { motion, AnimatePresence } from "framer-motion"

class ViewportModal extends React.Component{
    render(){
        return (
            <AnimatePresence>
                {this.props.active && 
                    <motion.div 
                        key="fade"

                        className="fade"
                        onClick={this.props.onClick} 
                        
                        initial={{ 
                            opacity:0
                        }}
                        animate={{ 
                            opacity:1
                        }}
                        exit={{ 
                            opacity:0
                        }}
                        transition={{ duration: 0.2 ,ease:"easeIn"}}
                    >
                        <motion.div 
                            key="viewportModal"
                            className="viewportModal" 
                            initial={{ 
                                y:window.innerWidth<800?'100%':'10%',
                            }}
                            animate={{ 
                                y:window.innerWidth<800?'0%':'0%',
                            }}
                            exit={{ 
                                y:window.innerWidth<800?'100%':'10%',
                            }}
                            transition={{ duration: 0.2 ,ease:"easeInOut"}}
                            onClick={(event)=>{event.stopPropagation()}}
                            data-size={this.props.size}
                        >
                            {this.props.children}
                        </motion.div> 
                    </motion.div>
                }
            </AnimatePresence>
        );
    }
}

export default ViewportModal;