import React from 'react';
import './ListSeparator.css';

class ListSeparator extends React.Component{
    render(){
        return <div className="ListSeparator">
            <p>{this.props.children}</p>
        </div>
    }
}

export default ListSeparator;