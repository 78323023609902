import Dexie from 'dexie';

window.authtoken = false; 

window.indexDbsupported = false;

document.addEventListener("DOMContentLoaded",function(e){
    (async()=>{
        if(retrieveSessionToken()){
            console.log('Session Token Found');
            window.authtoken = await retrieveSessionToken();
        }
    })()
});







async function retrieveSessionToken(){
    if(window.indexDbsupported){
        var dsb = new Dexie("SessionsDB");
        dsb.version(1).stores({
            authtoken: '++id,status,token'
        });
        const data = await dsb.authtoken.toCollection().last();
        return await data.token ?? false;
    }else{
        return localStorage.getItem('session') == null ? false : localStorage.getItem('session') ;
    }
}

function writeSessionToken(token){
    if(window.indexDbsupported){
        var dsb = new Dexie("SessionsDB");
        dsb.version(1).stores({
            authtoken: '++id,status,token'
        });
        dsb.authtoken.add({status:'active',token:token});
    }
    else{
        localStorage.setItem('session',token);
    }
}

window.authtoken = retrieveSessionToken()





function deleteSessionToken(){
    if(window.indexDbsupported){
        var dsb = new Dexie("SessionsDB");
        dsb.version(1).stores({
            authtoken: '++id,status,token'
        });
        dsb.authtoken.toCollection().delete();
    }else{
        localStorage.removeItem('session');
    }
}

function errorListener(errors){
    if(['auth.required_session','auth.invalid_session'].includes(errors[0].code)){
        console.log("AUTH_REQUIRED Handler");
        window.authtoken = false;
        deleteSessionToken();
    }
}


export let endpoint = 'https://api.republica.local';

export let API = {
    auth:function(obj,success,fail){
        fetch(endpoint+'/auth',{
            method:'POST',
            mode:'cors',
            body: JSON.stringify(obj)
        })
        .then(resp => resp.json())
        .then(response => {
            if(response.status){
                let data = response;
                window.authtoken = data.data.token;
                writeSessionToken(window.authtoken);
                success(data.data);
            }
            else{
                errorListener(response.exception);
                fail(response.exception);
            }
        })
        .catch(err => {
            fail([{code:"NETWORK",input:"*"}]);
        })
    },
    events:{
        list:function (success,fail){
            fetch(endpoint+'/events/list',{
                method:'GET',
                mode:'cors',
                headers:{
                    'Authorization':window.authtoken,
                    'Accept':"application/json"
                }
            })
            .then(resp => resp.json())
            .then(response => {
                
                if(response.status){
                    let data = response;
                    success(data.data)
                }
                else{
                    errorListener(response.exception);
                    fail(response.exception);
                }
            })
            .catch(err => {
                console.log(err);
                fail([{code:"NETWORK",input:"*"}]);
            })
        },
    },
    extension:{
        list:function (success,fail){
            fetch(endpoint+'/extension/list',{
                method:'GET',
                mode:'cors',
                headers:{
                    'Authorization':window.authtoken,
                    'Accept':"application/json"
                }
            })
            .then(resp => resp.json())
            .then(response => {
                
                if(response.status){
                    let data = response;
                    success(data.data)
                }
                else{
                    errorListener(response.exception);
                    fail(response.exception);
                }
            })
            .catch(err => {
                console.log(err);
                fail([{code:"NETWORK",input:"*"}]);
            })
        },
    },
    access:{
        list:function (success,fail){
            fetch(endpoint+'/access/list',{
                method:'GET',
                mode:'cors',
                headers:{
                    'Authorization':window.authtoken,
                    'Accept':"application/json"
                }
            })
            .then(resp => resp.json())
            .then(response => {
                
                if(response.status){
                    let data = response;
                    success(data.data)
                }
                else{
                    errorListener(response.exception);
                    fail(response.exception);
                }
            })
            .catch(err => {
                console.log(err);
                fail([{code:"NETWORK",input:"*"}]);
            })
        },
        set:function (id,success,fail){
            fetch(endpoint+'/access/set',{
                method:'POST',
                mode:'cors',
                headers:{
                    'Authorization':window.authtoken,
                    'Accept':"application/json"
                },
                body:JSON.stringify({id:id})
            })
            .then(resp => resp.json())
            .then(response => {
                if(response.status){
                    let data = response;
                    success(data.data)
                }
                else{
                    errorListener(response.exception);
                    fail(response.exception);
                }
            })
            .catch(err => {
                console.log(err);
                fail([{code:"NETWORK",input:"*"}]);
            })
        }
    },
    links:{
        shortcut:{
            list:function (success,fail){
                fetch(endpoint+'/links/shortcut/list',{
                    method:'GET',
                    mode:'cors',
                    headers:{
                        'Authorization':window.authtoken,
                        'Accept':"application/json"
                    }
                })
                .then(resp => resp.json())
                .then(response => {
                    
                    if(response.status){
                        let data = response;
                        success(data.data)
                    }
                    else{
                        errorListener(response.exception);
                        fail(response.exception);
                    }
                })
                .catch(err => {
                    console.log(err);
                    fail([{code:"NETWORK",input:"*"}]);
                })
            },
            get:function (id,success,fail){
                fetch(endpoint+'/links/shortcut/get',{
                    method:'POST',
                    mode:'cors',
                    headers:{
                        'Authorization':window.authtoken,
                        'Accept':"application/json"
                    },
                    body:JSON.stringify({
                        id:id
                    })
                })
                .then(resp => resp.json())
                .then(response => {
                    
                    if(response.status){
                        let data = response;
                        success(data.data)
                    }
                    else{
                        errorListener(response.exception);
                        fail(response.exception);
                    }
                })
                .catch(err => {
                    console.log(err);
                    fail([{code:"NETWORK",input:"*"}]);
                })
            },
            set:function (obj,success,fail){
                fetch(endpoint+'/links/shortcut/set',{
                    method:'POST',
                    mode:'cors',
                    headers:{
                        'Authorization':window.authtoken,
                        'Accept':"application/json"
                    },
                    body:JSON.stringify(obj)
                })
                .then(resp => resp.json())
                .then(response => {
                    
                    if(response.status){
                        let data = response;
                        success(data.data)
                    }
                    else{
                        errorListener(response.exception);
                        fail(response.exception);
                    }
                })
                .catch(err => {
                    console.log(err);
                    fail([{code:"NETWORK",input:"*"}]);
                })
            },
            delete:function (id,success,fail){
                fetch(endpoint+'/links/shortcut/delete',{
                    method:'POST',
                    mode:'cors',
                    headers:{
                        'Authorization':window.authtoken,
                        'Accept':"application/json"
                    },
                    body:JSON.stringify({id:id})
                })
                .then(resp => resp.json())
                .then(response => {
                    
                    if(response.status){
                        let data = response;
                        success(data.data)
                    }
                    else{
                        errorListener(response.exception);
                        fail(response.exception);
                    }
                })
                .catch(err => {
                    console.log(err);
                    fail([{code:"NETWORK",input:"*"}]);
                })
            }
        }
    },
    settings:{
        notification:{
            register:function (obj,success,fail){
                fetch(endpoint+'/settings/notification',{
                    method:'POST',
                    mode:'cors',
                    headers:{
                        'Authorization':window.authtoken,
                        'Accept':"application/json"
                    },
                    body:JSON.stringify(obj)
                })
                .then(resp => resp.json())
                .then(response => {
                    
                    if(response.status){
                        let data = response;
                        success(data.data)
                    }
                    else{
                        errorListener(response.exception);
                        fail(response.exception);
                    }
                })
                .catch(err => {
                    console.log(err);
                    fail([{code:"NETWORK",input:"*"}]);
                })
            }
        }
    },
    stats:{
        endpoint:{

        },
        city:{
            map:async function(context){
                let resp =  await fetch(endpoint+'/stats/city/map',{
                    method:'POST',
                    mode:'cors',
                    headers:{
                        'Authorization':await window.authtoken,
                        'Accept':"application/json"
                    },
                    body:JSON.stringify(context)
                })
                let obj = await resp.json()
                return obj.data;/*
                .then(response => {
                    
                    if(response.status){
                        let data = response;
                        success(data.data)
                    }
                    else{
                        errorListener(response.exception);
                        fail(response.exception);
                    }
                })
                .catch(err => {
                    console.log(err);
                    fail([{code:"NETWORK",input:"*"}]);
                })*/
            }
        }
        
    },
    parliament:{
        bourbon:{
            congressman:{
                list:function (obj={},success,fail){
                    fetch(endpoint+'/parliament/bourbon/congressman/list',{
                        method:'POST',
                        mode:'cors',
                        headers:{
                            'Authorization':window.authtoken,
                            'Accept':"application/json"
                        },
                        body:JSON.stringify(obj)
                    })
                    .then(resp => resp.json())
                    .then(response => {
                        
                        if(response.status){
                            let data = response;
                            success(data.data)
                        }
                        else{
                            errorListener(response.exception);
                            fail(response.exception);
                        }
                    })
                    .catch(err => {
                        console.log(err);
                        fail([{code:"NETWORK",input:"*"}]);
                    })
                },
                get:function (id,success,fail){
                    fetch(endpoint+'/parliament/bourbon/congressman/get',{
                        method:'POST',
                        mode:'cors',
                        headers:{
                            'Authorization':window.authtoken,
                            'Accept':"application/json"
                        },
                        body:JSON.stringify({id:id})
                    })
                    .then(resp => resp.json())
                    .then(response => {
                        
                        if(response.status){
                            let data = response;
                            success(data.data)
                        }
                        else{
                            errorListener(response.exception);
                            fail(response.exception);
                        }
                    })
                    .catch(err => {
                        console.log(err);
                        fail([{code:"NETWORK",input:"*"}]);
                    })
                }
            },
            group:{
                list:function (obj={},success,fail){
                    fetch(endpoint+'/parliament/bourbon/group/list',{
                        method:'POST',
                        mode:'cors',
                        headers:{
                            'Authorization':window.authtoken,
                            'Accept':"application/json"
                        },
                        body:JSON.stringify(obj)
                    })
                    .then(resp => resp.json())
                    .then(response => {
                        
                        if(response.status){
                            let data = response;
                            success(data.data)
                        }
                        else{
                            errorListener(response.exception);
                            fail(response.exception);
                        }
                    })
                    .catch(err => {
                        console.log(err);
                        fail([{code:"NETWORK",input:"*"}]);
                    })
                },
                get:function (id,success,fail){
                    fetch(endpoint+'/parliament/bourbon/group/get',{
                        method:'POST',
                        mode:'cors',
                        headers:{
                            'Authorization':window.authtoken,
                            'Accept':"application/json"
                        },
                        body:JSON.stringify({id:id})
                    })
                    .then(resp => resp.json())
                    .then(response => {
                        
                        if(response.status){
                            let data = response;
                            success(data.data)
                        }
                        else{
                            errorListener(response.exception);
                            fail(response.exception);
                        }
                    })
                    .catch(err => {
                        console.log(err);
                        fail([{code:"NETWORK",input:"*"}]);
                    })
                }
            }
        }
    },
    esus:{
        realtime:{
            flight:function (id,success,fail){
                fetch(endpoint+'/esus/realtime/flight',{
                    method:'POST',
                    mode:'cors',
                    headers:{
                        'Authorization':window.authtoken,
                        'Accept':"application/json"
                    },
                    body:JSON.stringify({flight:id})
                })
                .then(resp => resp.json())
                .then(response => {
                    
                    if(response.status){
                        let data = response;
                        success(data.data)
                    }
                    else{
                        errorListener(response.exception);
                        fail(response.exception);
                    }
                })
                .catch(err => {
                    console.log(err);
                    fail([{code:"NETWORK",input:"*"}]);
                })
            }
        }
    }
}

export default API;