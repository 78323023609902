import React from 'react';
import './Popup.css';
import { AnimatePresence, motion } from 'framer-motion';
import LANG from '../../Langs/en';

class Popup extends React.Component{
    constructor(props){
        super(props);
        this.handleClose = this.handleClose.bind(this)
    }

    handleClose(){

    }


    render(){
        return <AnimatePresence>
            {this.props.active && <motion.div
                initial={{
                    opacity:'0 !important'
                }}

                animate={{
                    opacity:1
                }}

                exit={{
                    opacity:0
                }}

                transition={{ duration:0.3 ,type:"ease"}}
            >
                <div className='fade' style={{display: 'flex',alignItems: 'center',justifyContent: 'center'}}>
                    <motion.div
                        initial={{
                            scale:0.95,
                            opacity:0
                        }}

                        animate={{
                            scale:1,
                            opacity:1
                        }}

                        exit={{
                            scale:0.95,
                            opacity:0
                        }}

                        transition={{ duration:0.15 ,type:"ease"}}
                    >
                        <div className='Popup'>
                            {this.props.icon && <img src={this.props.icon} alt="thumbnail"/>}
                            <h2 level={this.props?.level}>{this.props.title}</h2>
                            <p level={this.props?.level}>{this.props.description}</p>
                            <div className='buttonsContainer'>
                                {(this.props?.actions??[{onClick:()=>{},name:LANG.OK}]).map((e)=>
                                    <div className='button' level={e.level??this.props.level} onClick={()=>{
                                        this.props.handleClose();
                                        e.onClick(); 
                                    }}>{e.name}</div>
                                )}
                            </div>
                        </div>
                    </motion.div>
                </div>
            </motion.div>}
        </AnimatePresence>

        
    }
}

export default Popup;