import React from "react";
import './ProgressBar.css';

class ProgressBar extends React.Component{


    render(){
        let style={}

        if(this.props.progress!==undefined&&this.props.progress!==null&&this.props.progress!==false){
            style={
                animation:'none',
                width:(this.props.progress)+'%'
            }  
        }

        if(this.props.color!==undefined&&this.props.color!==null){
            style = {
                ...style,
                backgroundColor:this.props.color
            }
        }

        return (
                <div className="ProgressBar">
                    <div className="bar"><div className="progress" style={{...style,color:this.props.color}} > </div></div>
                    
                    {this.props.showText && this.props.progress && <div className="text" style={{color:this.props.color}}>{this.props.progress}%</div>}
                </div>
        );
    }
}

export default ProgressBar;

