import React from "react";
import { Navbar, Viewport , Form ,Formgroup,Input,Button,Responsive, ScrollView} from "../../UIKit/UIKit";
import { Outlet , redirect } from "react-router-dom";

import LANG from './../../Langs/en';
import { Formik } from "formik";
import ListItemOrganisation from "../../Kit/ListItemOrganisation";


import iconPhoto from "./../../bundles/picture.png";
import iconInstgram from "./../../bundles/instagram-app.png";
import icontwitter from "./../../bundles/twitter-app.png";
import icontelegram from "./../../bundles/telegram-app.png";
import icontiktok from "./../../bundles/tiktok-app.png";
import iconthreads from "./../../bundles/threads-app.png";
import iconfacebook from './../../bundles/facebook-app.png';
import pp from "./../../bundles/1.JPG";



class Page_SocialPost extends React.Component{
    constructor(props){
        super(props)
        this.setState({
            file:[]
        })
    }

    update(file){
        this.setState({
            file:file
        })
    }

    


    render(){return <Viewport>
        <Navbar title={LANG.APP.SOCIAL.POST.NAVBAR.TITLE} subtitle={LANG.APP.SOCIAL.POST.NAVBAR.SUBTITLE}></Navbar>
        
            <Formik onSubmit={console.log} initialValues={{text:''}}>
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    setFieldValue
                }) => (
                    <Form onSubmit={handleSubmit}>
                        <Formgroup>
                            <Input type="textarea" name="text" placeholder="Post Something ..." error={errors.id} onChange={handleChange} value={values.id} onBlur={handleBlur} />
                        </Formgroup>
                        <Formgroup>
                            {
                                this.state?.file?.map?.( element => {
                                    return element?<img style={{height:"60px",margin:"5px",display:'inline',objectFit:'contain'}} src={element.data} alt="tweet" />:<p>Loading ...</p>;
                                })
                            }
                        </Formgroup>
                        <Formgroup size="1/3">
                            <Input type="file" multiple placeholder={"Add"} disabled={isSubmitting} accept="image/*,video/*" max={4} name="file" setFieldValue={setFieldValue} setState={this.update} onChange={(e)=>{
                                const data = [];
                                setFieldValue('file',e.target.files)
                                for(let i = 0 ; i<e.target.files.lenght ; i++){
                                    data.push(null);
                                }

                                (new Array(...e.target.files)).forEach((element,index) => {
                                    
                                    const fr = new FileReader();
                                    fr.onload = ()=>{
                                        console.log(element,index,fr.result);
                                        if(fr.readyState === 2){
                                            data[index] = {
                                                name:element.name,
                                                data:fr.result
                                            }
                                            this.update(data);
                                            setFieldValue('file',data);
                                        }
                                        else{
                                            data[index] = false;
                                            this.update(data);
                                            setFieldValue('file',data);
                                        }
                                    }
                                    fr.readAsDataURL(element)
                                });
                            
                            
                            }} ><Button div class="circle" size="small" disabled={isSubmitting}><img src={iconPhoto} alt="Select Media" /></Button></Input>
                        </Formgroup>
                        <Formgroup size="1/3">
                            <Input type='datalist' label={"Accounts"} placeholder={'Choose where to publish'} class='round' size='small' data={[
                                {
                                    selected:true,
                                    clickable:true,
                                    thumbnail:pp,
                                    icon:iconInstgram,
                                    description:'Instagram',
                                    title:'Jordan Bardella',
                                    subtitle:"@j_bardella",
                                },
                                {
                                    clickable:true,
                                    thumbnail:pp,
                                    icon:icontwitter,
                                    description:'Twitter',
                                    title:"Jordan Bardella",
                                    subtitle:"@jordanbardella",
                                },
                                {
                                    clickable:true,
                                    thumbnail:pp,
                                    icon:icontelegram,
                                    description:'Telegram',
                                    title:"Jordan Bardella",
                                    subtitle:"@jordanbardella",
                                },
                                {
                                    clickable:true,
                                    thumbnail:pp,
                                    icon:icontiktok,
                                    description:'Tiktok',
                                    title:"Jordan Bardella",
                                    subtitle:"@jordanbardella",
                                },
                                {
                                    selected:true,
                                    clickable:true,
                                    thumbnail:pp,
                                    icon:iconthreads,
                                    description:'Threads',
                                    title:"Jordan Bardella",
                                    subtitle:"@jordanbardella",
                                },
                                {
                                    clickable:true,
                                    thumbnail:pp,
                                    icon:iconfacebook,
                                    description:'Facebook',
                                    title:"Jordan Bardella",
                                    subtitle:"jordan.bardella",
                                },
	                        ]}></Input>
                        </Formgroup>
                        <Formgroup size="1/3" >
                            <Button class="round"  size={"small"} onClick={handleSubmit} disabled={ isSubmitting || errors?.length!=null } >Post</Button>
                        </Formgroup>
                    </Form>
                )}
            </Formik>
    </Viewport>}
}

export default Page_SocialPost