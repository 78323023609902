import React from "react";
import './ListItemOrganisation.css'; 
import LANG from '../Langs/en';

class ListItemOrganisation extends React.Component{
    constructor(props){
        super(props);
        this.onClick = this.onClick.bind(this);
    }

    onClick(){
        this.props.onClick(this.props.data);
    }


    render(){
        return (
            <div className="ListItemOrganisation" style={{animationDelay:(((this.props?.index??0)+2)*150)+'ms'}} {...this.props} onClick={this.onClick} data-selected={this.props.selected} aria-selected={this.props.selected} >
                <div className="fill"></div>
                <div className="circle">
                    <div className="fill">
                    </div>
                </div>
                <h2>{this.props.data.organisation.name??'Error'}</h2>
                <h3>{LANG.ORGANISATION.ACCESS[this.props.data.name]??this.props.data.name}</h3>
            </div>
        )
    }
}

ListItemOrganisation.defaultProps = {
    onClick: console.log,
    name:'Unavailable',
    privilege:'Custom Access',
    id:0
}

export default ListItemOrganisation;