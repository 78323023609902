import React from "react";
import { motion, AnimatePresence } from "framer-motion";
import './Loading.css';
import logo1 from './Ressources/LogoLoading 1_WT@2x.png';
import logo2 from './Ressources/LogoLoading 2_WT@2x.png';

class Loading extends React.Component{


    render(){
        
        let style = {
            animation:'loadbar linear 0.7s infinite',
            width:'30%'
        }

        if(this.props.progress!==undefined&&this.props.progress!==null){
            style={
                animation:'none',
                width:(this.props.progress*100)+'% !important'
            }  
        }

        return (
            <AnimatePresence>
                {this.props.active && 
                    <motion.div 
                    key="fade"
                    className="loading"   
                    background-transparent={this.props.backgroundTransparent?.toString()??"false"}                 
                    initial={{ 
                        opacity:0
                    }}
                    animate={{ 
                        opacity:1
                    }}
                    exit={{ 
                        opacity:0
                    }}
                    transition={{ duration: 0.2 ,ease:"easeIn"}}
                    >
                        <div className="content">
                            {
                                (this.props?.cube??false) ? 
                                <div class="loader-cube-container" role="widget" >
                                    <div class="cube" ></div>
                                    <div class="cube" ></div>
                                    <div class="cube" ></div>
                                    <div class="cube" ></div>
                                    <div class="cube" ></div>
                                    <div class="cube" ></div>
                                    <div class="cube" ></div>
                                    <div class="cube" ></div>
                                    <div class="cube" ></div>
                                    <div class="cube" ></div>
                                    <div class="cube" ></div>
                                    <div class="cube" ></div>
                                    <div class="cube" ></div>
                                    <div class="cube" ></div>
                                    <div class="cube" ></div>
                                    <div class="cube" ></div>
                                    <div class="cube" ></div>
                                </div> : <>
                                    <img className="logo" invertable="true" src={logo1} alt="Loading Logo"/>
                                    <img className="logo" invertable="true" spin="true" src={logo2} alt="Loading Logo Spinner"/>
                                </>
                            }
                            
                            <div className="text">
                                <h2>{this.props.title}</h2>
                                {this.props.subtitle&&<h3>{this.props.subtitle}</h3>}
                            </div>
                            <div className="bar"></div>
                            <div className="progress" style={style}></div>
                        </div>
                    </motion.div>
                }
            </AnimatePresence>
        );
    }
}

export default Loading;

