import React from "react";
import './BottomBar.css';

class BottomBar extends React.Component{
    render(){
        return (
            <div className="bottombar" >{this.props.children}</div>
        );
    }
}

export default BottomBar;