import {React,useEffect,useState,useRef} from "react";
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official';
import './Parliament.css';

require('highcharts/modules/item-series')(Highcharts);






function Parliament(props){

    const options = {

        chart: {
             type: 'item',
             backgroundColor:'var(--main-color)',
         },
     
         title: {
             text: 'Distribution of seats',
             style:{
                color:'var(--tint-color)'
             }
         },
     
         subtitle: {
             text: 'Parliament Election 2024'
         },
     
         legend: {
            enabled:false,
             labelFormat: '{name} <span style="opacity: 0.4">{y}</span>'
         },
     
         series: [{
             name: 'Representatives',
             borderColor:"var(--terciary-main-color)",
             keys: ['name', 'y', 'color'],
             data: props.data,
             dataLabels: {
                 enabled: true,
                 format: '{point.label}',
                 style: {
                     textOutline: '3px contrast'
                 }
             },
     
             // Circular options
             center: ['50%', '88%'],
             size: '150%',
             startAngle: -100,
             endAngle: 100
         }],
     
         responsive: {
             rules: [{
                 condition: {
                     maxWidth: 700
                 },
                 chartOptions: {
                     series: [{
                         dataLabels: {
                             distance: -30
                         }
                     }]
                 }
             }]
         }
     }
    
       
    return <HighchartsReact

    highcharts={Highcharts}
    options={options}
    {...props}
  />;
}

export default Parliament;