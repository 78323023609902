import Dexie from "dexie";

export function setData(pkg){
    window.extensionc = {
        ...window.extensionc,
        [pkg.index]:pkg
    }

    var dsb = new Dexie("extensionDB");
    dsb.version(1).stores({
        extensions: 'index,data'
    });
    dsb.open()

    dsb.extensions.where('index').equals(pkg.index).delete();
    dsb.extensions.add({
        index:pkg.index,
        data:pkg
    })
}

export async function loadData(){
    var dsb = new Dexie("extensionDB");
    dsb.version(1).stores({
        extensions: 'index,data'
    });
    dsb.open();
    window.extensionc = {};
    (await dsb.extensions.toArray()).forEach(element => {
        console.log(element)
        window.extensionc[element.index] = element.data;
    });
}