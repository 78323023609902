import React from "react";
import "./ElectionCirco.css";
import { ProgressBar } from "../UIKit/UIKit";


class ElectionCirco extends React.Component{
    constructor(props){
        super(props)
        this.setState(props.data)
    }

    render(){
        if(!this.props.fight){
            return <div className="ElectionCirco" data-clickable={((typeof this.props.onClick) == 'function').toString()} onClick={this.props.onClick}>
                <h2>{this.props.data.departementName} - {this.props.data.circo}</h2>
                <h3>{this.props.data.departement}-{this.props.data.circo}</h3>
                <div className="LightBulbContainer">
                    {this.props.data.candidats.filter((e)=>e.status!=='OVER'&&e.status!=='AWAITING').map((e)=><div className="LightBulb" style={{background:e?.nuance?.color??'grey'}}></div>)}
                    {this.props.data.candidats.filter((e)=>e.status==='AWAITING').map((e)=><div className="LightBulb" style={{background:e?.nuance?.color??'grey',opacity:0.3}}></div>)}
                </div>
                
                {this.props.data.progress &&  <ProgressBar showText={true} progress={this.props.data.progress} /*color={this.props.data.progress===100?'var(--success-color)':undefined*//> }
            </div>;
        }
        else{
            return <div className="ElectionCirco" >
                <h2>{ this.props.fight.candidats.map((e)=>e.shortname).join(' vs ') }</h2>
                <h3>{ this.props.fight.count } Sector(s)</h3>
                <div className="LightBulbContainer">
                    {this.props?.fight?.candidats?.map?.((e)=><div className="LightBulb" style={ { background: e?.color??'grey' } }></div>)}
                </div>
            </div>;
        }
        
    }
}


export default ElectionCirco;