import React from "react";
import './ScrollView.css';

class ScrollView extends React.Component{
    constructor(props){
        super(props);
    }

    render(){
        return (
            <div className="scrollView" {...this.props} >
                {this.props.children}
            </div>
        )
    }
}

export default ScrollView;