import React from 'react';
import { 
    ScrollView , 
    Navbar , 
    Viewport ,
    Form,
    Formgroup,
    Input,
    Button,
    Popup,
    Switch,
    ListItem,
    Keypad,
    BottomBar,
    Loading,
    ProgressBar,
    NavBottomBar
} from '../../UIKit/UIKit';

import {API} from './../../javascript/API';
import LANG from '../../Langs/en';

import pp from './../../bundles/1.JPG'
import ElectionCirco from '../../Kit/ElectionCirco';
import { useState,useEffect } from 'react';
import { Outlet , redirect } from "react-router-dom";


import iconFrance from './../../bundles/france.png';
import iconRegion from './../../bundles/region.png';
import iconParliament from './../../bundles/parliament.png';
/*
class Page_Live extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            loading:false,
            data:false
        }

        this.componentDidMount = this.componentDidMount.bind(this)
    }

    componentDidMount(){
        this.setState({
            ...this.state,
            loading:true,
        })
        API.Live.result.get
            .then((e)=>{
                this.setState({
                    ...this.state,
                    loading:false,
                    data:e
                })
            })
    }


    render(){
        console.log(this.state)
        return <Viewport>
            <ScrollView more-padding-y="true">
                {!this.state.loading && this.state.data && this.state.data.circo.map((e)=><ElectionCirco data={e}/>) }
            </ScrollView>
            
            <Navbar title='Live Results' subtitle='Parliament Election' />
            <Loading active={this.state.loading!==false} title={LANG.LOADING.LOADING} subtitle={LANG.LOADING.UPDATING} />
        </Viewport>
    }
}*/

function Page_Live(){
    const [data,setData] = useState(false);
    const [loading,setLoading] = useState(true);

    function update(silent = false){
        setData(false)
        if(!silent) setLoading(true)
        API.Live.result.get
            .then((e)=>{
                setData(e)
                setLoading(false)
            })
            .catch( ()=>{
                setLoading(false)
            })
    }

    useEffect(() => {
        window.location.href = '/#/live/global';
        update(false);
    }, []);



    return <Viewport>
        <Outlet context={[data,setData]} />
        <NavBottomBar actions={[
            {
                icon:iconParliament,
                title:'Global',
                href:'/#/live/global'
            },
            {
                icon:iconRegion,
                title:'Sector',
                href:'/#/live/sector'
            }
        ]}/>
        <Loading active={loading!==false} title={LANG.LOADING.LOADING} subtitle={LANG.LOADING.UPDATING} />
    </Viewport>;

    
        

}

export default Page_Live;

