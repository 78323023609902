import React from "react";
import './Formgroup.css';

class Formgroup extends React.Component{
    render(){
        return (
            <div className="formgroup" data-size={this.props.size} >{this.props.children}</div>
        );
    }
}

export default Formgroup;