import React from "react";
import "./ListItemExtension.css";

import fetchProgress from "fetch-progress";
import { CircularProgressbar,buildStyles  } from "react-circular-progressbar";
import iconDownload from './../bundles/arrow-small-down.png';
import iconCheck from './../bundles/check.png';

import {endpoint} from "../javascript/sessionManager";
import * as Extension from './../javascript/extensionManager';

class ListItemExtension extends React.Component{
     constructor(props){
        super(props)
        this.state = {
            status:"download_available",
        }

        this.startDownload = this.startDownload.bind(this);
        this.checkLocal = this.checkLocal.bind(this);
        this.componentDidMount = this.componentDidMount.bind(this);
     }

     componentDidMount(){
        this.checkLocal();
     }

     checkLocal(){ 
        let status = "download_available";
        if(window.extensionc?.[this.props.data.index]!= undefined ){
            status = "downloaded"
            if(window.extensionc[this.props.data.index].version < this.props.data.version){
                status ="download_available"
            }
        }
        else if(this.state.progress=false){
            status = "download_progress"
        }else{
            status = "download_available"
        }
        this.setState({
            ...this.state,
            status :status
        })
    }

     startDownload(){
        this.setState({
            ...this.state,
            status:"download_progress"
        })
        const self = this;
        fetch(endpoint+this.props.data.source,{mode:'cors'})
        .then(
            fetchProgress({
                onProgress(progress) {
                    self.setState({...self.state, progress:progress.percentage });
                },
                onError(err) {
                    console.log(err);
                },
            })
        )
        .then(r=>r.json())
        .then((d) => {
            this.setState({
                ...this.state,
                status:"downloaded"
            })
            Extension.setData({
                ...this.props.data,
                data:d
            });
        })
    }



     render(){

        return (
            <div className="ListItemExtension" style={this.props.index==undefined?undefined:{animationDelay:((this.props.index+2)*150)+'ms'}} {...this.props} onClick={this.onClick} aria-selected={this.props.selected} >
                <div className="icon">
                        {this.state.status == "download_available"  &&<img clickable="true" onClick={this.startDownload} src={iconDownload}/>}
                        {this.state.status == "download_progress"  && <CircularProgressbar value={this.state.progress} maxValue={100} styles={buildStyles({
                            rotation: 0,
                            strokeLinecap: 'butt',
                            textSize: '16px',
                            pathTransitionDuration: 0.5,
                            pathColor: `var(--tint-color)`,
                            textColor: 'var(--tint-color)',
                            trailColor: 'var(--secondary-main-color)',
                            backgroundColor: 'var(--secondary-main-color)',
                        })} />}
                        {this.state.status == "downloaded"   && <img src={iconCheck}/> } 
                </div>
                <h2>{this.props.data.name??'Error'}</h2>
                <h3>{this.props.data.description}</h3>
            </div>
        )
     }
}

export default ListItemExtension;