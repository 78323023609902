import React from "react";
import { Error, Loading, NavBottomBar ,Navbar, ScrollView, Viewport } from "../../UIKit/UIKit";
import iconPlus from "../../bundles/plus.png";
import { API } from "../../javascript/API";
import LANG from "../../Langs/en";
import ListItemEvent from "../../Kit/ListItemEvent";
import arrowBack from '../../bundles/angle-left.png'



class Page_EventsMain extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            data:[],
            loading:false,
            error:false
        }
    }


    componentDidMount = ()=>{
        this.setState({
            error:false,
            loading:true,
            data:[]
        });

        API.Event.event.list().then((data)=>{
            this.setState({
                ...this.state,
                loading:{
                    title:LANG.LOADING.RETRIVING,
                    subtitle:LANG.LOADING.COLLECTING_OBJ
                },
                data:data
            })
        }).catch((err)=>{
            this.setState({
                ...this.state,
                loading:false,
                error:err
            })
            
        })
    }


    render = ()=><Viewport>
        <ScrollView>
            {this.state.loading && <Loading title={this.state.loading.title} subtitle={this.state.loading.subtitle}/>}
            {this.state.error && <Error title={this.state.error.title} subtitle={this.state.error.subtitle}/>}
            {!this.state.loading && !this.state.error && this.state.data.map((e)=><ListItemEvent object={e} />)}
        </ScrollView>
        <Navbar title='Events' subtitle='All Events' right={[{
            icon:arrowBack,
            title:'Add',
            onClick:()=>window.history.back()
        }]} />
    </Viewport>
}

export default Page_EventsMain;