import React from "react";
import { ListItem, Navbar, ScrollView ,Viewport} from "../../UIKit/UIKit";

import * as API     from "../../javascript/sessionManager";


import iconSecurity from "../../bundles/lock.png";
import iconProfil   from "../../bundles/id.png";
import iconNotification from '../../bundles/bell.png'
import iconPayment from '../../bundles/credit-card.png';
import iconSubscription from '../../bundles/terminal.png';
import iconMembers from '../../bundles/team.png';
import iconCongress from '../../bundles/bank.png';
import iconGeo from '../../bundles/radar.png';

class Page_SettingsOrganisation extends React.Component{
    render(){
        return <Viewport>
            <ScrollView more-padding-y="true" blend="less">
                <ListItem
                    title='Public Identity'
                    subtitle='edit your visual identity'
                    icon={iconProfil}
                    onClick={()=>console.log('fff')}
                />
                <ListItem 
                    title='Manage Electoral Mandate' 
                    subtitle='register your congress id' 
                    icon={iconCongress}
                    onClick={()=>console.log('fff')}
                /> 
                <ListItem
                    title='Plan Subscription'
                    subtitle='add functionality of your app'
                    icon={iconSubscription}
                    onClick={()=>console.log('fff')}
                />
                <ListItem
                    title='Billing and Payment'
                    subtitle='manage billing and payement methods'
                    icon={iconPayment}
                    onClick={()=>console.log('fff')}
                />
                <ListItem
                    title='Members'
                    subtitle='manage your team'
                    icon={iconMembers}
                    onClick={()=>console.log('fff')}
                />
                <ListItem
                    title='Radar'
                    subtitle='define your geographical zone of interest'
                    icon={iconGeo}
                    onClick={()=>console.log('fff')}
                />
                
            </ScrollView>
            <Navbar title="Settings" subtitle="Organisation" />
        </Viewport>
    }
}

export default Page_SettingsOrganisation;