import React from "react";
import './NavBottomBar.css';

class NavBottomBar extends React.Component{
    constructor(props){
        super(props)
        this.handleClick = this.handleClick.bind(this);
    }

    handleClick(e){
        
    }

    render(){
        return (
            <div className="NavBottomBar">
                {this.props.actions?.map(e => 
                    <div className="Action" data-selected={((window.location.pathname+window.location.search+window.location.hash).startsWith(e.href)).toString()} onClick={
                            ()=>{
                                window.location.href = e.href;
                                this.forceUpdate();
                            }
                        }>
                        {e.title}
                        <img src={e.icon} alt={e.title} />
                        <h3>{e.title}</h3>
                    </div>
                )}
            </div>
        )
    }
}

export default NavBottomBar;