import React, { useContext , useEffect, useState } from 'react';
import { ScrollView , Navbar , Viewport ,ListItem , Error , Loading} from '../../../UIKit/UIKit';


import arrowBack from '../../../bundles/angle-left.png';
import iconPassword from '../../../bundles/password.png';
import { API } from '../../../javascript/API';
import { LoadingContext } from '../../MainWindow';
import md5 from 'md5';
import Popup from '../../../UIKit/Kit/Popup';
import iconAlert from './../../../bundles/warning-red.png';
import LANG from '../../../Langs/en';

const Page_SettingsSessions =  (props)=>{


    const [loading,setLoading] = useState(false)
    const [error,setError] = useState(false)
    const [data,setData] = useState([])
    const [selected,setSelected] = useState(false)
    const [refresh,setRefresh] = useState((false));

    useEffect(()=>{
        setLoading({
            title:"Collecting ...",
            subtitle:"Gathering data"
        })
        API.sessions.list()
            .then((data)=>{
                setData(data)
                setLoading(false)
            })
            .catch((err)=>{
                setLoading(false)
                setError({
                    title:LANG.ERROR[err[0].code].TITLE,
                    subtitle:LANG.ERROR[err[0].code].SUBTITLE
                })
            })
    },[refresh])

    function handleClose(){
        setSelected(false)
    }

    function revoke(){
        API.sessions.delete(selected)
            .then(()=>{
                setRefresh(!refresh)
            })
            .catch(()=>{

            })
    }
    

    return <Viewport override="true">
        <ScrollView more-padding-y="true" blend="less">
            {data?.map((e)=><ListItem
                selected={(e.token === md5(window.authtoken)).toString()}
                title={e.client.device} 
                subtitle={e.client.location?.country + ', '+e.client.location?.regionName+', '+e.client.location?.city} 
                description={e.client.ip + ' - '+e.client.location?.as+" - "+(e.access?.organisation?.name??'No Access')} 
                active={e.active}
                onClick={(e.token === md5(window.authtoken) || !e.active )?undefined:()=>setSelected(e._id)}
            />)}
            <Loading title={loading?.title} subtitle={loading?.subtitle} active={loading}/>
            {error && <Error title={error?.title} subtitle={error?.subtitle}/>}
        </ScrollView>
        <Navbar title='Settings' subtitle='Sessions' left={[{
            icon:arrowBack,
            title:'Back',
            onClick:()=>window.history.back()
        }]} />
        <Popup 
            active={selected!==false} 
            handleClose={handleClose} 
            level="error" 
            title="Revoke Session" 
            description='The selected device will be disconnected from your account' 
            actions={[
                {
                    name:'Cancel',
                    onClick:()=>null
                },
                {
                    name:'Revoke',
                    onClick:revoke
                }
            ]} 
            icon={iconAlert} />
    </Viewport>
}

export default Page_SettingsSessions;