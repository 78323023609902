import React from 'react';
import './Responsive.css';


class Responsive extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            size:window.innerWidth,
            step:0
        }
    }

    resizeHanlder = (e)=>{
        console.log('resize')
        if(window.innerWidth < 850){
            this.setState({
                step: 0,
                size : window.innerWidth
            })
        }
        else if (window.innerWidth > 850 && window.innerWidth < 1200){
            this.setState({
                step:1,
                size : window.innerWidth
            })
        }
        else if(window.innerWidth > 1300){
            this.setState({
                step:2,
                size : window.innerWidth
            })
        }
    }

    componentDidMount(){
        this.resizeHanlder(null)
       window.addEventListener('resize',this.resizeHanlder)
    }

    componentWillUnmount(){
        window.removeEventListener('resize',this.resizeHanlder)
    }

    render(){
        let params = this.props.responsive??[];
        params = params.map((e)=>({
            size:e?.size??"1",
            fullH:e?.fullH??false,
            display:e?.display
        }))

        
        
        

        return (this.props.flex??false) ? 
            <div className='flex-responsive-container' >
                {this.props.children}
            </div> :
            <div className="responsive" data-responsive-fullheight={(params[this.state.step]?.fullH??false).toString()}  data-responsive-size={(params[this.state.step]?.size)??"1"} data-responsive-display={(params[this.state.step]?.display??true).toString()} >
                {this.props.children}
            </div> 
            
        ;
    }
}

export default Responsive;