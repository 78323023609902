import React from "react";
import { NavBottomBar , Viewport } from "../../UIKit/UIKit";
import { Outlet , redirect } from "react-router-dom";

import iconOrganisation from './../../bundles/building.png';
import iconAccount from './../../bundles/user.png'



class Page_SettingsMain extends React.Component{


    componentDidMount = ()=>{
        window.location.href = '/#/settings/account';
        this.forceUpdate();
    }


    render = ()=><Viewport>
        <Outlet/>
        <NavBottomBar actions={[
            {
                icon:iconOrganisation,
                title:'Team',
                href:'/#/settings/organisation'
            },
            {
                icon:iconAccount,
                title:'Account',
                href:'/#/settings/account'
            }
        ]}/>
    </Viewport>
}

export default Page_SettingsMain