const LANG= {
    OK:'OK',
    LOADING:{
        LOADING:"Loading",
        CHECKING_CREDENTIALS:"Checking credentials",
        CHECKING_OTP:"Checking OTP code",
        AUTHENTICATE:"Authentication",
        SETTING_ACCESS:"Setting up your account",
        CONNECTING:"Connecting",
        RETRIVING:"Retriving",
        COLLECTING:"Collecting data",
        COLLECTING_OBJ:"Collecting Object",
        UPDATING:"Updating",
        SENDING:"Sending",
        SENDING_UPDATE:"Sending Update",
        DELETING:"Deleting",
        REMOVING:"Removing",
        DISABLING:"Disabling",
        ACTIVATION:"Activation",
        TWOAF:"2AF Authentication",
        ERASING:"Erasing",
        ARCHIVING:"Archiving",
        UPLOADING:"Uploading",
        DELETING_OBJ:"Deleting object",
        ERASING_OBJ:"Erasing object",
        REMOVING_OBJ:"Removing Object"
    },
    MENU:{
        STATISTICS:"Statistics",
        ESUS:"Esus",
        PARLIAMENT:"Parliament",
        TASK:"Tasks",
        MEDIA_LIB:"Media Library",
        CLOUD:"Cloud",
        CALENDAR:"Calendar",
        EVENTS:"Events",
        WEBSITES:"Websites",
        LINK:"Links"
    },
    ERROR:{
        "local.network":{
            TITLE:"Disconnected",
            SUBTITLE:'Check your internet connection',
            DESRICPTON:"Your device seems offline please check your internet connection"
        },
        "password.minimum":{
            TITLE:"Password too short",
            SUBTITLE:"Password must be at least 8 characters",
            DESCRIPTION:"Password must be at least 8 characters"
        },
        "auth.required":{
            TITLE:"Invalid Session",
            SUBTITLE:"Initiate a new session"
        },
        ACTION_NOT_ALLOWED:{
            TITLE:"Not Allowed",
            SUBTITLE:"You cannot perform this action"
        },
        SESSION_REVOKED:{
            TITLE:"Session Revoked",
            SUBTITLE:"Initiate a new session"
        },
        INFO_CURRENTLY_NA:{
            TITLE:'NO DATA',
            SUBTITLE:'Information currently not available',
            DESCRIPTION:'This information is currently not available, try again later'
        }

    },
    ORGANISATION:{
        ACCESS:{
            ADMIN:"Administrator",

            DIR_CABINET:"Director of Cabinet",
            HEAD_CABINET:"Head of Cabinet",
            ASSISTANT:"Assistant",
            

            AN_PARLEMENT_ASSISTANT:"Parlmentary Assistant",
            AN_LOCAL_PARLEMENT_ASSISTANT:"Local Parlementary Assistant",
            AN_GROUP_ASSISTANT:"Group Parlementary Assistant",
            
            EU_LOCAL_ASSISTANT:"EU Local Parlementary Assistant",
            EU_PARLEMENT_ASSISTANT:"EU Parlementary Assistant",
            EU_GROUP_ASSISTANT:"EU Group Parlementary Assistant",

            CONTENT_CREATOR:"Contents Creator",
            ESP:"External Services Provider",
            WEBMASTER:"Webmaster",
            PUBLISHER:"Publisher",
            REDACTOR:"Redactor"

        },
        TYPE:{
            EU_CONGRESSMAN_CABINET:"Member of the European Parliament",
            EU_GROUP:"EU Parlementary Group",

            CONGRESS_GROUP:"Parlementary Group",
            CONGRESSMAN_CABINET:"Congressman",

            ASSOCIATION:"Association"
        }
    },
    BUTTON:{
        CONNECT:"Connect",
        DELETE:"Delete",
        SAVE:"Save",
        OPEN:"Open"
    },
    INPUT:{
        ID:"Login",
        PASS:"Password"
    },
    NAVBAR:{
        ORGANISATION_SELECT:{
            TITLE:"Organisation",
            SUBTITLE:"Pickup your organisation"
        },
        LINKS:{
            TITLE:"Links",
            SUBTITLE:"Shortners"
        }
    },
    APP:{
        LINKS:{
            SHORTCUT:{
                NAVBAR:{
                    TITLE:"Links",
                    SUBTITLE:"Shortcut"
                },
                OBJECT:{
                    NAVBAR:{
                        TITLE:"Shortcut"
                    }
                }
            }
        },
        EXTENSION:{
            NAVBAR:{
                TITLE:"Extensions",
                SUBTITLE:"Download ressources"
            }
        },
        STATS:{
            GEOTYPE:{
                city:"City",
                disctrict:"District",
                department:"Department",
                region:"Region"
            },
            INPUT:{

            },
            UI:{
                VALUE:"Value",
                GEOOBJ:"Geo Object",
                CONTEXT:"Context",
                VOTE:"Vote"
            }
        },
        PARLIAMENT:{
            BOURBON:{
                CONGRESSMAN:{
                    group:"Groupe Parlementaire",
                    circo:"Circonscription",
                    departement:"Department",
                    rate:"Participation",
                    votesCount:"Votes",
                    total:"Total",
                    terminated:"Terminated since"
                }
            }
        },
        EVENTS:{
            LIST:{
                NAVBAR:{
                    TITLE:"Events",
                    SUBTITLE:"Organised Events"
                }
            },
            ITEM:{
                NAVBAR:{
                    TITLE:"Event",
                    SUBTITLE:"Organised Events"
                }
            }
            

            
        },
        SOCIAL:{
            POST:{
                NAVBAR:{
                    TITLE:"Social",
                    SUBTITLE:"Post"
                }
            }
        },
        LIVE:{
            ELECTION:{
                STATUS:{
                    ABSOLUTE_MAJORITY:'Absolute Majority',
                    RELATIVE_MAJORITY:'Relative Majority',
                    GROUP_ALLOWED:'Group Acquired',
                    NONE:''
                }
            }
        }
    }
};

export default LANG;

