import React from 'react';
import { 
    ScrollView , 
    Navbar , 
    Viewport ,
    Form,
    Formgroup,
    Input,
    Button,
    Popup,
    Switch,
    ListItem,
    Keypad,
    BottomBar,
    Loading
} from '../../../UIKit/UIKit';
import { Formik } from 'formik';
import {API} from './../../../javascript/API';
import QRCode from 'qrcode';


import arrowBack from '../../../bundles/angle-left.png';
import iconPassword from '../../../bundles/password.png';
import LANG from '../../../Langs/en';

import iconSuccess from './../../../bundles/success-green.png';
import iconError from './../../../bundles/warning-red.png';

class Page_Settings2AF extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            loading:false,
            token:false,
            error:false,
            OTPscreen:false,
        }

        this.componentDidMount = this.componentDidMount.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.enable = this.enable.bind(this)
        this.sendUpdate = this.sendUpdate.bind(this)

    }

    componentDidMount(){
        this.setState({
            ...this.state,
            loading:true,
        })
        API.settings['2AF'].get()
            .then((e)=>{
                this.setState({
                    ...this.state,
                    loading:false,
                    ...e 
                })
            })
    }

    handleChange(e){
        this.setState({
            ...this.state,
            status:(e.target.checked)
        });

        this.sendUpdate(e.target.checked)
    }

    sendUpdate(status){
        console.log(status)
        if(status){
            this.setState({
                ...this.state,
                loading:{
                    title:LANG.LOADING.ACTIVATION,
                    subtitle:LANG.LOADING.TWOAF
                }
            })
            API.settings['2AF'].engage()
                .then((e)=>{
                    this.setState({
                        ...this.state,
                        token:e,
                        loading:false
                    })
                    QRCode.toDataURL(e.url)
                        .then((u)=>{
                            this.setState({
                                ...this.state,
                                loading:false,
                                token:{
                                    ...e,
                                    qrcode:u
                                }
                            })
                        })
                })
        }
        else{
            this.setState({
                ...this.state,
                loading:{
                    title:LANG.LOADING.DISABLING,
                    subtitle:LANG.LOADING.TWOAF
                }
            })
            console.log('disable')
            API.settings['2AF'].disable()
                .then((e)=>{
                    this.setState({
                        ...this.state,
                        loading:false,
                        status:false
                    })
                })
                .catch((err)=>{
                    this.setState({
                        ...this.state,
                        loading:false,
                        status:true
                    })
                })

        }
    }

    enable(values,a){
        console.log(values)
        this.setState({
            ...this.state,
            loading:{
                title:LANG.LOADING.CHECKING_OTP,
                subtitle:LANG.LOADING.TWOAF
            }
        })
        API.settings["2AF"].enable(values)
            .then((e)=>{
                this.setState({
                    OTPscreen:false,
                    token:false,
                    loading:false,
                    status:true
                })
            })
            .catch((err)=>{
                this.setState({

                })
            })
    }



    render(){
        console.log(this.state)
        return <Viewport override="true">
            <ScrollView blend="false">
                <ListItem
                    title="2 Authentication Factor"
                    subtitle="Strong Security for your account"
                >
                    <Switch onClick={this.handleChange} checked={this.state.status} loading={this.state.loading}/>
                </ListItem>
            </ScrollView>
            
            <Navbar title='Settings' subtitle='2 Authentication Factor' left={[{
                icon:arrowBack,
                title:'Back',
                onClick:()=>window.history.back()
            }]} />
            

            {this.state.token && 
                <Viewport>
                        <Navbar title="Security" subtitle="Adding Token" />
                        <img src={this.state.token.qrcode} alt="2AF QrCode token" style={{
                            height:'200px',
                            width:'100%',
                            objectFit:'contain'
                        }}/>
                        <Formgroup>
                            <Input type="text" value={this.state.token.key} noBorder={true} style={{textAlign:'center'}}/>
                        </Formgroup>
                        <Formgroup>
                            <Button onClick={()=>{window.location.href=this.state.token.url}} >Add 2AF Key</Button>
                        </Formgroup>
                        <BottomBar>
                            <Formgroup>
                                <Button onClick={()=>this.setState({...this.state,OTPscreen:true})} >Continue</Button>
                            </Formgroup>
                        </BottomBar>
                    </Viewport>
                

            }
            {this.state.OTPscreen &&
  
                <Viewport>
                        <Navbar title="Security" subtitle="Enter your 2FA code" />
                        <Formik
                            initialValues={{totp:''}}
                            onSubmit={this.enable}
                        >
                            {({
                                values,
                                errors,
                                touched,
                                handleChange,
                                handleBlur,
                                handleSubmit,
                                isSubmitting,
                                setFieldValue
                            }) => (
                                    <Keypad handleChange={handleChange} handleClose={null} name="totp" error={errors.totp} setFieldValue={setFieldValue} value={values.totp} handleSubmit={handleSubmit} autocomplete="one-time-code"/>
                            
                            )}
                        </Formik>
                    
                </Viewport>
            }
            <Loading active={this.state.loading!=false} title={this.state.loading.title} subtitle={this.state.loading.subtitle} />
            
        </Viewport>
    }
}

export default Page_Settings2AF;