import React from "react";
import './Input.css';
import iconSpin from './Ressources/LogoLoading 2_WT@2x.png';
import {Button, Navbar, ScrollView, ViewportModal , Loading, ListItem} from './../UIKit';
import ListItemOrganisation from "../../Kit/ListItemOrganisation";
import iconClose from './../../bundles/cross-small.png'
import LANG from '../../Langs/en';
class Input extends React.Component{
    constructor(props){
        super(props)
        this.id = Math.random()*1000000
        this.state = {
            viewportModal:false,
            option:false,
            value:[]
        }
    }
    async componentDidMount(){
        this.setState({
            ...this.state,
            option:await this.props.load()
        })
    }

    render(){

        switch(this.props.type){
            case 'textarea' :
                return (<div className="input" data-noborder={this.props?.noBorder?.toString?.()??"false"} >
                            {this.props.label!=null?<label>{this.props.label}</label>:null}
                            <textarea
                                id={this.id}
                                disabled={this.props.disabled}
                                autoComplete={this.props.autoComplete}
                                placeholder={this.props.placeholder}
                                type={this.props.type}
                                onChange={this.props.onChange}
                                onBlur={this.props.onBlur}
                                name={this.props.name}
                                pattern={this.props.pattern}
                                maxLength={this.props.maxLength}
                                size={this.props.size}
                                level={this.props.error!=null?'error':'none'}
                                style={this.props.style}
                            >{this.props.value}</textarea>
                            <div className="error" role="alert" aria-relevant="all">{this.props.error}</div>
                        </div>);
            case 'file' : 
                return (
                    <div className="input"  style={{border:"none !important"}} >

                        <input style={{border:"none !important"}} id={this.id} type="file" name={this.props.name} disabled={this.props.disabled} onChange={this.props.onChange} onBlur={this.props.onBlur} accept={this.props.accept} multiple={this.props.multiple} />

                        <label style={{border:"none !important"}} for={this.id}>{this.props.children??this.props.placeholder}</label>
                    </div>
                );
            case 'datalist':
                return <>
                        <Button class={this.props.class} size={this.props.size} onClick={()=>{this.setState({
                            ...this.state,
                            viewportModal:!this.state.viewportModal
                        })}}>
                            {JSON.stringify(this.state.value.length===0?undefined:this.state.value)??this.props.label}
                        </Button>
                        <ViewportModal size="small" active={this.state.viewportModal} onClick={()=>this.setState({
                            ...this.state,
                            viewportModal:!this.state.viewportModal
                        })}>
                            <ScrollView more-padding-y="true">
                                {this.state.option && <Loading title={LANG.LOADING.LOADING} subtitle={LANG.LOADING.COLLECTING_OBJ} />}
                                {this.props.data.map((e)=><ListItem {...e} />)}
                            </ScrollView>
                        <Navbar title={this.props.label} subtitle={this.props.placeholder} right={[{icon:iconClose,onClick:()=>this.setState({...this.state,viewportModal:!this.state.viewportModal})}]}/>
                    </ViewportModal>
                </>
                
            default:
                return (<div className="input" data-noborder={this.props?.noBorder?.toString?.()??"false"} >
                            {this.props.label!=null?<label>{this.props.label}</label>:null}
                            <input 
                                disabled={this.props.disabled}
                                autoComplete={this.props.autoComplete}
                                placeholder={this.props.placeholder}
                                type={this.props.type}
                                onChange={this.props.onChange}
                                onBlur={this.props.onBlur}
                                value={this.props.value}
                                name={this.props.name}
                                pattern={this.props.pattern}
                                maxLength={this.props.maxLength}
                                size={this.props.size}
                                level={this.props.error!=null?'error':'none'}
                                style={this.props.style}
                            />
                            <div className="error" role="alert" aria-relevant="all">{this.props.error}</div>
                        </div>);
                
        }
    }
}

Input.defaultProps = {
    autoComplete:'off',
    placeholder:'Input',
    type:"text",
    disabled:false,
    onChange:()=>{},
    onBlur:()=>{},
    value:''
}
export default Input;